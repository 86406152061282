import { DevicePreviewView } from "@/coordinator/workspace/preview/DevicePreviewView";
import { Tabs, TabsList, TabsTrigger } from "@/infrastructure/uikit/components/ui/tabs";
import { Laptop2, Smartphone, Tablet } from "lucide-react";
import React, { FC, useEffect, useState } from "react";

type PreviewViewProps = {
  projectId: string;
  projectPlatform: string;
};

export const PreviewView: FC<PreviewViewProps> = (props: PreviewViewProps) => {
  const [device, setDevice] = useState("MOBILE");
  const previewUrl = localStorage.getItem(`PREVIEW_URL_${props.projectId}`) ?? "";

  useEffect(() => {
    setDevice(props.projectPlatform === "REACT" ? "DESKTOP" : "MOBILE")
  }, [props.projectPlatform])

  return (
    <>
      <div className={"pt-4"}>
        <Tabs
          className="flex flex-row justify-center"
          onValueChange={(value: string) => {
            setDevice(value);
          }}
          value={device}
        >
          <TabsList>
            <TabsTrigger value="MOBILE" className="w-20">
              <Smartphone className={"w-4 h-4"} />
            </TabsTrigger>
            <TabsTrigger value="TABLET" className="w-20">
              <Tablet className={"w-4 h-4"} />
            </TabsTrigger>
            <TabsTrigger value="DESKTOP" className="w-20">
              <Laptop2 className={"w-4 h-4"} />
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <div className={"flex flex-col justify-center w-full h-full items-center pb-8"}>
        <DevicePreviewView device={device}>
          <div>
            {previewUrl ? (
              <iframe className="w-full h-full" src={previewUrl}></iframe>
            ) : (
              <span className="w-full h-full flex flex-row justify-center items-center text-center">
                Preview is not available
                <br /> you can enable it from setting
              </span>
            )}
          </div>
        </DevicePreviewView>
      </div>
    </>
  );
};
