import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { useToast } from "@/infrastructure/uikit/components/ui/use-toast";
import { useState } from "react";
import { integrationRepository } from "../di/IntegrationComponent";
import { IntegrationModel, IntegrationProjectModel } from "../domain/model/integrationModel";

export type IntegrationUiState = {
  integrations: IntegrationModel[] | undefined;
  blockIntegrationsInstalled: IntegrationProjectModel[] | undefined;
  actionIntegrationsInstalled: IntegrationProjectModel[] | undefined;
  integration: IntegrationModel | undefined;
  integrationProject: IntegrationProjectModel | undefined;
  searchQuery: string | undefined;
};

export type IntegrationUiAction = {
  getIntegrations: (organizationId: string, projectId: string, kind: string) => void;
  getIntegrationsInstalled: (organizationId: string, projectId: string) => void;
  search: (searchQuery: string) => void;
  getIntegration: (organizationId: string, integrationId: string) => void;
  checkInstallationIntegration: (organizationId: string, projectId: string, integrationId: string) => void;
  upgradeOrInstallOrUnInstall: (organizationId: string, projectId: string, integrationId: string) => void;
};

export type IntegrationViewModel = {
  uiState: IntegrationUiState;
  uiAction: IntegrationUiAction;
};

export const useIntegrationViewModel = () => {
  const [uiState, setUiState] = useState({} as IntegrationUiState);
  const { toast } = useToast();

  const [unFilterIntegration, setUnFilterIntegration] = useState([] as IntegrationModel[]);

  async function getIntegrations(organizationId: string, platformSupport: string, kind: string) {
    const result: ResultModel<IntegrationModel[]> = await integrationRepository.getIntegrations(
      organizationId,
      kind,
      platformSupport,
      0,
      1000
    );

    if (result.onSuccess) {
      setUiState({
        ...uiState,
        integrations: result.onSuccess,
      });
      setUnFilterIntegration(result.onSuccess ?? []);
    } else {
      setUiState({
        ...uiState,
        integrations: undefined,
      });
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function getIntegrationsInstalled(organizationId: string, projectId: string) {
    if (!organizationId || !projectId) return;
    const resultBlock: ResultModel<IntegrationProjectModel[]> = await integrationRepository.getIntegrationsInstalled(
      organizationId,
      projectId,
      "BLOCK"
    );

    const resultAction: ResultModel<IntegrationProjectModel[]> = await integrationRepository.getIntegrationsInstalled(
      organizationId,
      projectId,
      "ACTION"
    );

    setUiState({
      ...uiState,
      blockIntegrationsInstalled: resultBlock.onSuccess,
      actionIntegrationsInstalled: resultAction.onSuccess,
    });

    if (resultBlock.onError || resultAction.onError) {
      toast({ description: resultBlock.onError ?? "", variant: "destructive" });
      toast({ description: resultAction.onError ?? "", variant: "destructive" });
    }
  }

  function search(searchQuery: string) {
    if (!searchQuery) {
      setUiState({
        ...uiState,
        searchQuery: searchQuery,
        integrations: unFilterIntegration,
      });
    } else {
      const list =
        uiState.integrations?.filter((integration: IntegrationModel) => {
          return integration.name.includes(searchQuery);
        }) ?? [];
      setUiState({
        ...uiState,
        searchQuery: searchQuery,
        integrations: list,
      });
    }
  }

  async function getIntegration(organizationId: string, integrationId: string) {
    const result: ResultModel<IntegrationModel> = await integrationRepository.getIntegration(
      organizationId,
      integrationId
    );

    if (result.onSuccess) {
      setUiState((prevState) => {
        return {
          ...prevState,
          integration: result.onSuccess,
        };
      });
    } else {
      setUiState((prevState) => {
        return {
          ...prevState,
          integration: undefined,
        };
      });
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function checkInstallationIntegration(organizationId: string, projectId: string, integrationId: string) {
    const result: ResultModel<IntegrationProjectModel> = await integrationRepository.checkInstallationIntegration(
      organizationId,
      projectId,
      integrationId
    );
    if (result.onSuccess) {
      setUiState((prevState) => {
        return {
          ...prevState,
          integrationProject: result.onSuccess,
        };
      });
    } else {
      setUiState((prevState) => {
        return {
          ...prevState,
          integrationProject: undefined,
        };
      });
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function upgradeOrInstallOrUnInstall(organizationId: string, projectId: string, integrationId: string) {
    const installation = uiState.integrationProject;
    if (installation?.hasUpdate) {
      await integrationRepository.upgradeIntegration(organizationId, projectId, integrationId);
      await checkInstallationIntegration(organizationId, projectId, integrationId);
    } else if (installation?.id) {
      await integrationRepository.unInstall(organizationId, projectId, integrationId);
      await checkInstallationIntegration(organizationId, projectId, integrationId);
    } else {
      await integrationRepository.install(organizationId, projectId, integrationId);
      await checkInstallationIntegration(organizationId, projectId, integrationId);
    }
    getIntegrationsInstalled(organizationId, projectId);
  }

  return {
    uiState: uiState,
    uiAction: {
      getIntegrations,
      getIntegrationsInstalled,
      search,
      getIntegration,
      checkInstallationIntegration,
      upgradeOrInstallOrUnInstall,
    },
  } as IntegrationViewModel;
};
