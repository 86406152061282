import { Label } from "@/infrastructure/uikit/components/ui/label";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import React from "react";
import { ActionTriggerPropertyModel } from "../../domain/model/ActionModel";

type ActionTextFieldPickerViewProps = {
  property: ActionTriggerPropertyModel;
  value: string;
  onChange: (key: string, newValue: string) => void;
};

export const ActionTextFieldPickerView = (props: ActionTextFieldPickerViewProps) => {
  return (
    <>
      <div
        key={props.property.key + Math.random().toString()}
        className="grid w-full items-center gap-1.5 bg-muted p-2 rounded-lg"
      >
        <Label
          key={props.property.key + Math.random().toString()}
          htmlFor={"actionTriggerTextFieldPicker_" + props.property.key}
        >
          {props.property.key}
        </Label>
        <Input
          autoComplete="off"
          key={props.property.key + Math.random().toString()}
          defaultValue={props.value}
          type="actionTriggerTextFieldPicker"
          id={"actionTriggerTextFieldPicker_" + props.property.key}
          onChange={(event) => {
            props.onChange(props.property.key ?? "", event.target.value);
          }}
        />
      </div>
    </>
  );
};
