import React, {FC} from "react";
import {userAuthLoginViewModel} from "./AuthLoginViewModel";
import {Button} from "@/infrastructure/uikit/components/ui/button";
import {AuthLoginFormView} from "./AuthLoginFormView";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logoImage from "@/infrastructure/uikit/icons/logo.svg";

export const AuthLoginView: FC = () => {
  const {uiState} = userAuthLoginViewModel();
  return (
    <>
      <div
        className="container relative hidden min-h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0 bg-background">
        <div className="relative hidden h-full flex-col p-10 text-foreground lg:flex">
          <div className="absolute inset-0 bg-muted"/>
          <div className="relative z-20 flex items-center text-lg font-medium">
            <img className="h-8 w-8" src={logoImage} alt="logo"/>
            <span className="px-4 text-foreground">Nativeblocks</span>
          </div>
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-8">
              <p className="text-xl">
                &ldquo;{uiState.todayQuote.quote}&rdquo;
              </p>
              <footer className="text-sm">{uiState.todayQuote.author}</footer>
            </blockquote>
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight text-foreground">
                Login
              </h1>
              <p className="text-sm text-muted-foreground">
                Enter your email below to login into your account
              </p>
            </div>
            <AuthLoginFormView/>
            <p className="px-6 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our
              <Button variant={"link"}
              onClick={() => {
                window.open("https://nativeblocks.io/terms-of-service/")
              }}
              >Terms of Service</Button>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
