import React, { createContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authRepository } from "./login/di/AuthComponent";

export const NativeAuth = createContext(false);

export const NativeAuthProvider = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!authRepository.getAuthToken()) {
      return navigate("/login");
    }
  }, [location.pathname]);

  return <NativeAuth.Provider value={!!authRepository.getAuthToken()}>{props.children}</NativeAuth.Provider>;
};
