import { WorkspaceViewModel } from "@/coordinator/workspace/WorkspaceViewModel";
import { BlockDataView } from "@/feature/block/presentation/BlockDataView";
import { BlockPropertyView } from "@/feature/block/presentation/BlockPropertyView";
import { BlockViewModel } from "@/feature/block/presentation/BlockViewModel";
import { FrameViewModel } from "@/feature/frame/presentation/FrameViewModel";
import { IntegrationViewModel } from "@/feature/integration/presentation/IntegrationViewModel";
import { BlockActionView } from "@/feature/action/presentation/BlockActionView";
import { ActionViewModel } from "@/feature/action/presentation/ActionViewModel";
import { VariableViewModel } from "@/feature/variable/presentation/VariableViewModel";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import { Braces, SlidersHorizontal, Wand2 } from "lucide-react";
import React, { FC, useEffect } from "react";

type RightSidePanelProps = {
  workspaceViewModel: WorkspaceViewModel;
  integrationViewModel: IntegrationViewModel;
  blockViewModel: BlockViewModel;
  variableViewModel: VariableViewModel;
  actionViewModel: ActionViewModel;
  frameViewModel: FrameViewModel;
};

export const RightSidePanel: FC<RightSidePanelProps> = (props: RightSidePanelProps) => {
  return (
    <>
      <div className={"w-full"}>
        <RightSidePanelContent
          variableViewModel={props.variableViewModel}
          blockViewModel={props.blockViewModel}
          frameViewModel={props.frameViewModel}
          activePanel={props.workspaceViewModel.uiState.rightPanel}
        />
      </div>
      <div className="flex flex-col space-y-2">
        <Button
          disabled={!props.blockViewModel.uiState.activeBlock}
          onClick={() => {
            props.workspaceViewModel.uiAction.selectRightPanel("BLOCK_PROPERTY");
          }}
          variant={props.workspaceViewModel.uiState.rightPanel === "BLOCK_PROPERTY" ? "secondary" : "ghost"}
          size="icon"
        >
          <SlidersHorizontal className="h-4 w-4" />
        </Button>
        <Button
          disabled={!props.blockViewModel.uiState.activeBlock}
          onClick={() => {
            props.workspaceViewModel.uiAction.selectRightPanel("BLOCK_DATA");
          }}
          variant={props.workspaceViewModel.uiState.rightPanel === "BLOCK_DATA" ? "secondary" : "ghost"}
          size="icon"
        >
          <Braces className="h-4 w-4" />
        </Button>
        <Button
          disabled={!props.blockViewModel.uiState.activeBlock}
          onClick={() => {
            props.workspaceViewModel.uiAction.selectRightPanel("BLOCK_ACTION");
          }}
          variant={props.workspaceViewModel.uiState.rightPanel === "BLOCK_ACTION " ? "secondary" : "ghost"}
          size="icon"
        >
          <Wand2 className="h-4 w-4" />
        </Button>
      </div>
    </>
  );
};

type RightSidePanelContentProps = {
  blockViewModel: BlockViewModel;
  variableViewModel: VariableViewModel;
  frameViewModel: FrameViewModel;
  activePanel: string | null;
};

const RightSidePanelContent: FC<RightSidePanelContentProps> = (props: RightSidePanelContentProps) => {
  useEffect(() => {
    props.variableViewModel.uiAction.getVariables(props.frameViewModel.uiState.selectedFrame?.id ?? "");
  }, [props.frameViewModel.uiState.selectedFrame?.id ?? ""]);
  if (props.activePanel === "BLOCK_PROPERTY") {
    return (
      <BlockPropertyView
        blockViewModel={props.blockViewModel}
        frameId={props.frameViewModel.uiState.selectedFrame?.id ?? ""}
        projectPlatform={props.frameViewModel.uiState.scaffold?.projectPlatform ?? ""}
      />
    );
  } else if (props.activePanel === "BLOCK_DATA") {
    return (
      <BlockDataView
        blockViewModel={props.blockViewModel}
        variables={
          props.variableViewModel.uiState.variables?.map((variable) => {
            return { id: variable.key ?? "", text: variable.key ?? "" };
          }) ?? []
        }
        frameId={props.frameViewModel.uiState.selectedFrame?.id ?? ""}
      />
    );
  } else if (props.activePanel === "BLOCK_ACTION") {
    return (
      <BlockActionView
        blockEvents={
          props.blockViewModel.uiState.activeBlock?.events?.map((event) => {
            return event.event;
          }) ?? []
        }
        blockKey={props.blockViewModel.uiState.activeBlock?.key ?? ""}
        frameId={props.frameViewModel.uiState.selectedFrame?.id ?? ""}
      />
    );
  } else {
    return <></>;
  }
};
