import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { useToast } from "@/infrastructure/uikit/components/ui/use-toast";
import { useState } from "react";
import { organizationRepository } from "../di/OrganizationComponent";
import { OrganizationModel } from "../domain/model/OrganizationModel";

export type OrganizationViewModel = {
  uiState: OrganizationUiState;
  uiAction: OrganizationUiAction;
};

type OrganizationUiState = {
  organizations: OrganizationModel[];
  currentOrganization?: OrganizationModel | null;
  billingPortalUrl: string | null;
};

type OrganizationUiAction = {
  getOrganizations: () => void;
  changeCurrentOrganization: (organizationId: string) => void;
  createOrganization: (name: string) => void;
  deleteContributor: (organizationId: string, contributorEmail: string) => void;
  addContributor: (organizationId: string, contributorEmail: string) => void;
  getBillingPortalSession: (organizationId: string) => void;
};

export const useOrganizationViewModel = () => {
  const { toast } = useToast();
  const [uiState, setUiState] = useState({} as OrganizationUiState);

  async function getOrganizations() {
    const result: ResultModel<OrganizationModel[]> = await organizationRepository.getOrganizations();

    if (result.onSuccess?.[0]) {
      const currentOrganization = result.onSuccess.find(
        (item) => item.id === organizationRepository.getCurrentOrganization()
      );
      setUiState({
        currentOrganization: currentOrganization ?? result.onSuccess[0],
        organizations: result.onSuccess,
        billingPortalUrl: null,
      });
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  function changeCurrentOrganization(organizationId: string) {
    const org = uiState.organizations.find((item) => item.id === organizationId);
    organizationRepository.changeCurrentOrganization(organizationId);
    setUiState({
      ...uiState,
      billingPortalUrl: null,
      currentOrganization: org,
    });
  }

  async function createOrganization(name: string) {
    const result = await organizationRepository.createOrganization(name);

    if (result.onSuccess) {
      toast({
        description: `${name} has created successfully`,
        variant: "default",
      });
      getOrganizations();
    }
  }

  async function deleteContributor(organizationId: string, contributorEmail: string) {
    const result = await organizationRepository.deleteOrganizationContributor(organizationId, contributorEmail);

    if (result.onSuccess) {
      toast({
        description: `${contributorEmail} has deleted successfully`,
        variant: "default",
      });

      const contributors = uiState.currentOrganization?.contributors?.filter((contributor) => {
        return contributor.email !== contributorEmail;
      });
      setUiState({
        ...uiState,
        currentOrganization: {
          ...uiState.currentOrganization,
          contributors: contributors,
        } as OrganizationModel,
      });
      getOrganizations();
    }
  }

  async function addContributor(organizationId: string, contributorEmail: string) {
    const result = await organizationRepository.addOrganizationContributor(organizationId, contributorEmail);

    if (result.onSuccess) {
      toast({
        description: `${contributorEmail} has added successfully`,
        variant: "default",
      });
      getOrganizations();
    }
  }

  async function getBillingPortalSession(organizationId: string) {
    if (!organizationId) {
      return;
    }
    const result = await organizationRepository.getBillingPortalSession(organizationId);
    if (result.onSuccess) {
      setUiState({
        ...uiState,
        billingPortalUrl: result.onSuccess,
      });
    }
  }

  return {
    uiState: uiState,
    uiAction: {
      getOrganizations,
      changeCurrentOrganization,
      createOrganization,
      deleteContributor,
      addContributor,
      getBillingPortalSession,
    },
  } as OrganizationViewModel;
};
