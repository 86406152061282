import { Button } from "@/infrastructure/uikit/components/ui/button";
import { Card } from "@/infrastructure/uikit/components/ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/infrastructure/uikit/components/ui/dialog";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";
import { Box, Plus } from "lucide-react";
import React, { useState } from "react";
import { ActionIntegrationModel } from "../../domain/model/ActionModel";
import { useDrop } from "react-dnd";

export const EventNodeType = (props: any) => {
  const [name, setName] = useState("");
  const [selectedIntegration, setSelectedIntegration] = useState<ActionIntegrationModel | null>(null);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "TriggerNodeType",
    drop: () => {
      return {
        id: "EVENT",
        then: "NEXT"
      };
    },
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div className="px-4 py-2 shadow-md rounded-md bg-card border-2 border-muted-400 flex flex-col justify-center items-center">
      <span className="text-sm text-card-foreground text-center">{props.name}</span>

      <Dialog>
        <DialogTrigger asChild>
          <Button ref={drop} variant="secondary" size="icon" className="rounded-full">
            <Plus className="h-4 w-4" />
          </Button>
        </DialogTrigger>
        <DialogContent className={"h-2/3"}>
          <DialogHeader>
            <DialogTitle>Add a new trigger</DialogTitle>
            <DialogDescription>Provide a name for the action</DialogDescription>
          </DialogHeader>
          <div>
            <Label className="sr-only" htmlFor="name">
              Name
            </Label>
            <Input
              id="name"
              placeholder="Name for trigger"
              type="text"
              autoComplete="off"
              autoCapitalize="none"
              autoCorrect="off"
              onChange={(event: any) => setName(event.target.value)}
            />
          </div>
          <ScrollArea className="w-full">
            <div className="grid grid-cols-2 gap-4">
              {props.installedActions?.map((integration: ActionIntegrationModel) => {
                return (
                  <Card
                    key={integration.id}
                    className={`${integration.id === selectedIntegration?.id ? "border-muted-foreground" : ""}`}
                    onClick={() => {
                      setSelectedIntegration(integration);
                    }}
                  >
                    <div className={"flex flex-row p-2 justify-start items-center gap-4"}>
                      <Box className="h-8 w-8 my-4" />
                      {integration.name}
                    </div>
                  </Card>
                );
              })}
            </div>
          </ScrollArea>
          <DialogClose asChild={true}>
            <Button
              onClick={() => {
                props.addActionTrigger(selectedIntegration?.id ?? "", name, "", "NEXT");
                setSelectedIntegration(null);
              }}
              disabled={!name}
              variant={"default"}
              autoFocus
              className="w-full"
            >
              Add
            </Button>
          </DialogClose>
        </DialogContent>
      </Dialog>
    </div>
  );
};
