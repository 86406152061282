import {LocalizationLanguageRepository} from "@/feature/localization/domain/repository/LocalizationLanguageRepository";
import {ResultModel} from "@/infrastructure/result/model/ResultModel";
import {AvailableLanguageModel} from "../domain/model/AvailableLanguageModel";
import {LanguageModel} from "../domain/model/LanguageModel";
import {
  ADD_LANGUAGE_MUTATION,
  GET_AVAILABLE_LANGUAGES_QUERY,
  GET_LANGUAGES_QUERY
} from "@/feature/localization/data/LocalizationQuery";

export class LocalizationLanguageRepositoryImpl implements LocalizationLanguageRepository {

  private readonly graphqlClient: any;

  constructor(graphqlClient: any) {
    this.graphqlClient = graphqlClient;
  }

  async getLanguages(): Promise<ResultModel<LanguageModel[]>> {
    try {
      const result = await this.graphqlClient.query({
        query: GET_LANGUAGES_QUERY,
      });
      const list: LanguageModel[] = result?.data?.languages.map((item: any) => {
        return this.toLanguageModel(item)
      })
      return <ResultModel<LanguageModel[]>>{
        onSuccess: list,
      };
    } catch (error: any) {
      return <ResultModel<LanguageModel[]>>{
        onError: error.message,
      };
    }
  }

  async getAvailableLanguages(): Promise<ResultModel<AvailableLanguageModel[]>> {
    try {
      const result = await this.graphqlClient.query({
        query: GET_AVAILABLE_LANGUAGES_QUERY,
      });
      const list: AvailableLanguageModel[] = result?.data?.availableLanguages.map((item: any) => {
        return this.toAvailableLanguageModel(item)
      })
      return <ResultModel<AvailableLanguageModel[]>>{
        onSuccess: list,
      };
    } catch (error: any) {
      return <ResultModel<AvailableLanguageModel[]>>{
        onError: error.message,
      };
    }
  }

  async addLanguage(code: string): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: ADD_LANGUAGE_MUTATION,
        variables: {
          input: {
            code: code,
          },
        },
      });

      return {
        onSuccess: {},
      } as ResultModel<any>;
    } catch (error: any) {
      return {
        onError: error.message,
      } as ResultModel<string>;
    }
  }

  toLanguageModel(item: any): LanguageModel {
    return {
      id: item.id ?? "",
      name: item.name ?? "",
      code: item.code ?? ""
    } as LanguageModel
  }

  toAvailableLanguageModel(item: any): AvailableLanguageModel {
    return {
      id: item.id ?? "",
      name: item.name ?? "",
      code: item.code ?? ""
    } as AvailableLanguageModel
  }


}