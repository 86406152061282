import { useWorkspaceState } from "@/coordinator/workspace/state/WorkspaceContext";
import { LocalizationAddLanguageView } from "@/feature/localization/presentation/LocalizationAddLanguageView";
import { LocalizationAddView } from "@/feature/localization/presentation/LocalizationAddView";
import { LocalizationPublishView } from "@/feature/localization/presentation/LocalizationPublishView";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/infrastructure/uikit/components/ui/dropdown-menu";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/infrastructure/uikit/components/ui/table";
import { ArrowLeft, MoreHorizontal, Search, Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const LocalizationView = () => {
  const navigate = useNavigate();

  const { localizationViewModel } = useWorkspaceState();
  const [search] = useState(localizationViewModel.uiState.searchQuery);

  useEffect(() => {
    localizationViewModel.uiAction.getAvailableLanguages();
    localizationViewModel.uiAction.getLanguages();
    localizationViewModel.uiAction.getLocalizations();
  }, []);

  return (
    <>
      <div className="flex flex-col w-full bg-background h-full">
        <div className="flex flex-row w-full justify-between items-center h-16 bg-card shadow-md px-4">
          <div className="flex flex-row items-center space-x-2">
            <Button
              onClick={() => {
                navigate(-1);
                localizationViewModel.uiAction.search("");
              }}
              variant="ghost"
              size="icon"
              className="rounded-full"
            >
              <ArrowLeft className="h-4 w-4" />
            </Button>
            <p>Localization</p>
          </div>
        </div>
        <ScrollArea className="flex flex-col w-full h-full p-4">
          <div className="flex flex-row p-1 space-x-4 justify-between">
            <div>
              <div className="relative flex items-center max-w-2xl ">
                <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 transform" />
                <Input
                  defaultValue={search}
                  type="text"
                  autoComplete="off"
                  placeholder="Localization key"
                  className="w-96 pl-8"
                  onChange={(event: any) => {
                    localizationViewModel.uiAction.search(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className={"space-x-2"}>
              <LocalizationPublishView localizationViewModel={localizationViewModel} />
              <LocalizationAddView localizationViewModel={localizationViewModel} />
              <LocalizationAddLanguageView localizationViewModel={localizationViewModel} />
            </div>
          </div>
          <div className="space-y-2">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Key / Language</TableHead>
                  {localizationViewModel.uiState.languages?.map((language) => {
                    return <TableHead key={language.id}>{language.name}</TableHead>;
                  })}
                </TableRow>
              </TableHeader>
              <TableBody>
                {Object.keys(localizationViewModel.uiState.localizations ?? {}).map((key: string) => {
                  return (
                    <TableRow key={key}>
                      <TableCell className="truncate max-w-96" key={key}>{key}</TableCell>
                      {localizationViewModel.uiState.localizations?.[key]?.map((localization) => {
                        return (
                          <TableCell key={localization.id}>
                            <Input
                              defaultValue={localization.value}
                              placeholder="Translate value"
                              type="text"
                              autoCapitalize="none"
                              autoComplete="off"
                              autoCorrect="off"
                              onChange={(event: any) =>
                                localizationViewModel.uiAction.updateLocalization(
                                  key,
                                  event.target.value,
                                  localization.languageCode
                                )
                              }
                            />
                          </TableCell>
                        );
                      })}
                      <TableCell key={Math.random()}>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant={"ghost"} size={"icon"}>
                              <MoreHorizontal />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            <DropdownMenuItem
                              onSelect={() => {
                                localizationViewModel.uiAction.deleteLocalization(key);
                              }}
                            >
                              <Trash2 className="w-4 h-4 mx-2" />
                              Delete {key}
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </ScrollArea>
      </div>
    </>
  );
};
