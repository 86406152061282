import { useWorkspaceState } from "@/coordinator/workspace/state/WorkspaceContext";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/infrastructure/uikit/components/ui/dropdown-menu";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";
import { ChevronRightSquare, MoreVertical, Trash2 } from "lucide-react";
import React, { FC, useEffect } from "react";
import { ActionModel } from "../domain/model/ActionModel";
import { ActionCreateView } from "./ActionCreateView";

export type BlockActionViewProps = {
  frameId: string;
  blockEvents: string[];
  blockKey: string;
};

export const BlockActionView: FC<BlockActionViewProps> = (props: BlockActionViewProps) => {
  const { actionViewModel } = useWorkspaceState();

  useEffect(() => {
    actionViewModel.uiAction.getActions(props.frameId);
  }, [props.frameId, props.blockKey]);

  return (
    <>
      <ScrollArea>
        <>
          <div className="space-y-2">
            <ActionCreateView
              frameId={props.frameId}
              blockEvents={props.blockEvents}
              blockKey={props.blockKey}
              actionViewModel={actionViewModel}
            />
            {actionViewModel.uiState.actions
              ?.filter((action) => action.key === props.blockKey)
              ?.map((action: ActionModel) => {
                return (
                  <Button key={action.id} variant={"ghost"} className="justify-between flex flex-row w-full">
                    <div
                      className="flex flex-row items-center"
                      onClick={() => {
                        actionViewModel.uiAction.selectAction(props.frameId, props.blockKey, action);
                      }}
                    >
                      <ChevronRightSquare className="h-4 w-4 mx-2" />
                      {`${action.key} [${action.event}]`}
                    </div>
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <MoreVertical className="h-4 w-4" />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <>
                          <DropdownMenuItem
                            onSelect={() => {
                              actionViewModel.uiAction.deleteAction(props.frameId, action.id ?? "");
                            }}
                          >
                            <Trash2 className="w-4 h-4 mx-2" />
                            Delete {action.key}
                          </DropdownMenuItem>
                        </>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </Button>
                );
              })}
          </div>
        </>
      </ScrollArea>
    </>
  );
};
