export function groupBy<T>(arr: T[], fn: (item: T) => any) {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr);
    const group = prev[groupKey] || [];
    group.push(curr);
    return {...prev, [groupKey]: group};
  }, {});
}

export function recordKeys<K extends PropertyKey, T>(object: Record<K, T>) {
  return Object.keys(object) as (K)[];
};

export function recordEntries<K extends PropertyKey, T>(object: Record<K, T>) {
  return Object.entries(object) as ([K, T])[];
}

// export function groupBy<T>(arr: T[], fn: (item: T) => any): Map<any, T[]> {
//   const resultMap = new Map<any, T[]>();
//   for (const item of arr) {
//     const key = fn(item);
//     const group = resultMap.get(key) || [];
//     group.push(item);
//     resultMap.set(key, group);
//   }
//
//   return resultMap;
// }

export function filterMap<K, V>(map: Map<K, V>, predicate: (key: K, value: V) => boolean): Map<K, V> {
  const result = new Map<K, V>();
  map.forEach((value, key) => {
    if (predicate(key, value)) {
      result.set(key, value);
    }
  });

  return result;
}