import React from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { dracula } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { Button } from "./button";
import { Copy } from "lucide-react";

type MarkdownRendererProps = {
  children: string;
};

export function MarkdownRenderer({ children: markdown }: MarkdownRendererProps) {
  const Pre = ({ children }: any) => (
    <pre>
      <Button
        onClick={() => {
          navigator.clipboard.writeText(children.props.children ?? "");
        }}
        className="absolute right-9 m-1"
        variant={"ghost"}
        size={"icon"}
      >
        <Copy />
      </Button>
      {children}
    </pre>
  );
  return (
    <Markdown
      className={"overflow-scroll select-text"}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        pre: Pre,
        code({ node, inline, className, children, ...props }: any) {
          const match = /language-(\w+)/.exec(className || "");

          return !inline && match ? (
            <SyntaxHighlighter style={dracula} PreTag="div" language={match[1]} {...props}>
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {markdown}
    </Markdown>
  );
}
