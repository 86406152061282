import { Label } from "@/infrastructure/uikit/components/ui/label";
import { Textarea } from "@/infrastructure/uikit/components/ui/textarea";
import React from "react";
import { ActionTriggerPropertyModel } from "../../domain/model/ActionModel";

type ActionTextAreaPickerViewProps = {
  property: ActionTriggerPropertyModel;
  value: string;
  onChange: (key: string, newValue: string) => void;
};

export const ActionTextAreaPickerView = (props: ActionTextAreaPickerViewProps) => {
  return (
    <>
      <div
        key={props.property.key + Math.random().toString()}
        className="grid w-full items-center gap-1.5 bg-muted p-2 rounded-lg"
      >
        <Label
          key={props.property.key + Math.random().toString()}
          htmlFor={"actionTextAreaPicker_" + props.property.key}
        >
          {props.property.key}
        </Label>
        <Textarea
          key={props.property.key + Math.random().toString()}
          defaultValue={props.value}
          id={"actionTextAreaPicker_" + props.property.key}
          onChange={(event) => {
            props.onChange(props.property.key ?? "", event.target.value);
          }}
        />
      </div>
    </>
  );
};
