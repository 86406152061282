import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { useToast } from "@/infrastructure/uikit/components/ui/use-toast";
import { useState } from "react";
import { frameRepository } from "../di/FrameComponent";
import { FrameModel, ScaffoldModel } from "../domain/model/FrameModel";

export type FrameUiState = {
  scaffold: ScaffoldModel | null;
  selectedFrame: FrameModel | null;
};

export type FrameUiAction = {
  getScaffold: () => void;
  selectFrame: (frame: FrameModel) => void;
  addFrame: (name: string, route: string, isStarter: boolean) => void;
  deleteFrame: (route: string) => void;
  publishFrame: (name: string, route: string) => void;
  updateFrame: (id: string, name: string, route: string, isStarter: boolean) => void;
};

export type FrameViewModel = {
  uiState: FrameUiState;
  uiAction: FrameUiAction;
};

export const useFrameViewModel = () => {
  const [uiState, setUiState] = useState({} as FrameUiState);
  const { toast } = useToast();

  async function addFrame(name: string, route: string, isStarter: boolean) {
    const result: ResultModel<any> = await frameRepository.addFrame(name, route, "FRAME", isStarter);
    if (result.onSuccess) {
      getScaffold();
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function deleteFrame(route: string) {
    const result: ResultModel<any> = await frameRepository.deleteFrame(route);
    if (result.onSuccess) {
      selectFrame(null)
      getScaffold();
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function publishFrame(name: string, route: string) {
    const result: ResultModel<any> = await frameRepository.publishFrame(route);
    if (result.onSuccess) {
      toast({
        description: `${name} has published successfully`,
        variant: "default",
      });
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function updateFrame(id: string, name: string, route: string, isStarter: boolean) {
    const result: ResultModel<any> = await frameRepository.updateFrame(id, name, route, "FRAME", isStarter);
    if (result.onSuccess) {
      getScaffold();
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function getScaffold() {
    const result: ResultModel<ScaffoldModel> = await frameRepository.getScaffold();

    if (result.onSuccess) {
      setUiState({
        ...uiState,
        scaffold: result.onSuccess,
      });
    } else {
      setUiState({
        ...uiState,
        scaffold: null,
      });
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  function selectFrame(frame: FrameModel | null) {
    setUiState({
      ...uiState,
      selectedFrame: frame,
    });
  }

  return {
    uiState: uiState,
    uiAction: {
      getScaffold,
      selectFrame,
      addFrame,
      deleteFrame,
      updateFrame,
      publishFrame,
    },
  } as FrameViewModel;
};
