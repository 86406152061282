import { Button } from "@/infrastructure/uikit/components/ui/button";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import { cn } from "@/infrastructure/uikit/lib/utils";
import React, { FC, useState } from "react";
import { userAuthLoginViewModel } from "./AuthLoginViewModel";
import { Separator } from "@/infrastructure/uikit/components/ui/separator";

export const AuthLoginFormView: FC = () => {
  const { uiAction } = userAuthLoginViewModel();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className={cn("grid gap-6")}>
      <div className="grid gap-2">
        <div className="grid gap-1">
          <Label className="sr-only" htmlFor="email">
            Email
          </Label>
          <Input
            id="email"
            placeholder="name@example.com"
            type="email"
            autoCapitalize="none"
            autoComplete="email"
            autoCorrect="off"
            onChange={(event: any) => setEmail(event.target.value)}
          />

          <Label className="sr-only" htmlFor="password">
            password
          </Label>
          <Input
            id="password"
            placeholder="*********"
            type="password"
            autoCapitalize="none"
            autoComplete="password"
            autoCorrect="off"
            onChange={(event: any) => setPassword(event.target.value)}
          />
        </div>
        <Button
          onClick={() => {
            uiAction.login(email, password);
          }}
          variant={"default"}
        >
          Login with Email
        </Button>
        <div className="flex flex-row justify-between items-center w-2/5 gap-6">
          <Separator />
          OR
          <Separator />
        </div>
        <Button
          onClick={() => {
            uiAction.loginWithGoogle();
          }}
          variant="outline"
          className="w-full"
        >
          <img src="https://www.svgrepo.com/show/475656/google-color.svg" alt="Google" className="h-4 w-4 mx-2" />
          Continue with Google
        </Button>
      </div>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
      </div>
    </div>
  );
};
