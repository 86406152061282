import { AppEnvironmentConfig } from "@/infrastructure/network/EnvironmentProvider";
import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { ProjectRepository } from "../../domain/ProjectRepository";
import { ProjectModel } from "../../domain/model/ProjectModel";
import { ProjectUsageModel } from "../../domain/model/ProjectUsageModel";
import {
  ADD_PROJECT_API_KEY_MUTATION,
  CREATE_PROJECT_MUTATION,
  DELETE_PROJECT_API_KEY_MUTATION,
  DELETE_PROJECT_MUTATION,
  GET_PROJECT_API_KEY_QUERY,
  GET_PROJECT_USAGE_BY_ROUTE_QUERY,
  GET_PROJECT_USAGE_BY_TOTAL_QUERY,
  GET_PROJECTS_QUERY,
  UPDATE_PROJECT_MUTATION,
} from "./ProjectQueries";
import { ApiKeyModel } from "../../domain/model/ApiKeyModel";

export class ProjectRepositoryImpl implements ProjectRepository {
  private readonly graphqlClient: any;

  constructor(graphqlClient: any) {
    this.graphqlClient = graphqlClient;
  }

  async createProject(
    organizationId: string,
    projectName: string,
    platform: string
  ): Promise<ResultModel<ProjectModel>> {
    try {
      const result = await this.graphqlClient.mutate({
        mutation: CREATE_PROJECT_MUTATION,
        variables: {
          input: {
            organizationId: organizationId,
            name: projectName,
            platform: platform,
          },
        },
      });
      return <ResultModel<ProjectModel>>{
        onSuccess: this.mapToModel(result.data.createProject),
      };
    } catch (error: any) {
      return <ResultModel<ProjectModel>>{
        onError: error.message,
      };
    }
  }

  async updateProject(projectName: string, projectId: string, organizationId: string): Promise<ResultModel<never>> {
    try {
      const result = await this.graphqlClient.mutate({
        mutation: UPDATE_PROJECT_MUTATION,
        variables: {
          input: {
            name: projectName,
            id: projectId,
            organizationId: organizationId,
          },
        },
      });
      return <ResultModel<never>>{
        onSuccess: result.data.updateProject.id,
      };
    } catch (error: any) {
      return <ResultModel<never>>{
        onError: error.message,
      };
    }
  }

  async deleteProject(projectId: string, organizationId: string): Promise<ResultModel<never>> {
    try {
      const result = await this.graphqlClient.mutate({
        mutation: DELETE_PROJECT_MUTATION,
        variables: {
          input: {
            id: projectId,
            organizationId: organizationId,
          },
        },
      });
      return <ResultModel<never>>{
        onSuccess: result.data.deleteProject.id,
      };
    } catch (error: any) {
      return <ResultModel<never>>{
        onError: error.message,
      };
    }
  }

  async getProjects(organizationId: string): Promise<ResultModel<ProjectModel[]>> {
    try {
      const result = await this.graphqlClient.query({
        query: GET_PROJECTS_QUERY,
        variables: {
          organizationId: organizationId,
        },
      });

      const list: ProjectModel[] = result?.data?.projects?.map((item?: any) => {
        return this.mapToModel(item);
      });
      return <ResultModel<ProjectModel[]>>{
        onSuccess: list,
      };
    } catch (error: any) {
      return <ResultModel<ProjectModel[]>>{
        onError: error.message,
      };
    }
  }

  saveProjectApikey(apiKey: string): ResultModel<never> {
    localStorage.setItem("apiKey", apiKey);
    return <ResultModel<never>>{
      onSuccess: undefined,
    };
  }

  async addApiKey(projectId: string, name: string): Promise<ResultModel<never>> {
    try {
      const result = await this.graphqlClient.mutate({
        mutation: ADD_PROJECT_API_KEY_MUTATION,
        variables: {
          input: {
            projectId: projectId,
            name: name,
          },
        },
      });
      return <ResultModel<never>>{
        onSuccess: result.data.addProjectApiKey,
      };
    } catch (error: any) {
      return <ResultModel<never>>{
        onError: error.message,
      };
    }
  }

  async deleteApiKey(projectId: string, apiKey: string): Promise<ResultModel<never>> {
    try {
      const result = await this.graphqlClient.mutate({
        mutation: DELETE_PROJECT_API_KEY_MUTATION,
        variables: {
          input: {
            projectId: projectId,
            apiKey: apiKey,
          },
        },
      });
      return <ResultModel<never>>{
        onSuccess: result.data.deleteProjectApiKey,
      };
    } catch (error: any) {
      return <ResultModel<never>>{
        onError: error.message,
      };
    }
  }

  async getProjectApiKeys(projectId: string): Promise<ResultModel<ApiKeyModel[]>> {
    try {
      const result = await this.graphqlClient.query({
        query: GET_PROJECT_API_KEY_QUERY,
        variables: {
          projectId: projectId,
        },
      });

      const list: ApiKeyModel[] = result?.data?.projectApiKeys?.map((item?: any) => {
        return <ApiKeyModel>{
          name: item?.name ?? "",
          apiKey: item?.apiKey ?? "",
          expireAt: item?.expireAt ?? "",
        };
      });
      return <ResultModel<ApiKeyModel[]>>{
        onSuccess: list,
      };
    } catch (error: any) {
      return <ResultModel<ApiKeyModel[]>>{
        onError: error.message,
      };
    }
  }

  private getDoc(project: any): string {
    if (project.platform === "ANDROID") {
      return `
  Add dependecies
  ---------------------------------------------
  Root gradle

  \`\`\`groovy
  repositories {
      mavenCentral()
  }
  \`\`\`

  Module gradle

  \`\`\`groovy
  dependencies {
      implementation ("io.nativeblocks:nativeblocks-android:1.1.0")
  }
  \`\`\`
  
  Let's get started by initializing the SDK
  
  \`\`\`kotlin
  NativeblocksManager.initialize(
    applicationContext = this,
    edition = NativeblocksEdition.Cloud(
        endpoint = "${AppEnvironmentConfig.API_ENDPOINT_GRAPHQL_URL}",
        apiKey = NATIVEBLOCKS_API_KEY,
        developmentMode = true
    )
  )
  \`\`\`
        `;
    } else if (project.platform === "IOS") {
      return `
Add dependecies
---------------------------------------------
Add SDK with SPM to your project with the following link


\`\`\`code
Link
\`\`\`

Let's get started by initializing the SDK

\`\`\`swift
import Nativeblocks

NativeblocksManager.initialize(
    edition: .cloud(
        endpoint: "${AppEnvironmentConfig.API_ENDPOINT_GRAPHQL_URL}",
        apiKey: NATIVEBLOCKS_API_KEY,
        developmentMode: true
    )
)
\`\`\`
      `;
    } else if (project.platform === "REACT") {
      return `
Add dependecies
---------------------------------------------
\`\`\`bash
yarn add @nativeblocks/nativeblocks-react
\`\`\`

Or

\`\`\`bash
npm i @nativeblocks/nativeblocks-react
\`\`\`


Let's get started by initializing the SDK

\`\`\`ts
NativeblocksManager.initialize({
  endpoint: "${AppEnvironmentConfig.API_ENDPOINT_GRAPHQL_URL}",
  apiKey: "API_KEY",
  developmentMode: true,
});
\`\`\`
      `;
    } else {
      return "";
    }
  }

  private mapToModel(item?: any): ProjectModel {
    return <ProjectModel>{
      id: item?.id ?? "",
      name: item?.name ?? "",
      apiKeys: item?.apiKeys ?? [],
      doc: this.getDoc(item),
      platform: item?.platform ?? "",
      image: "",
    };
  }

  private mapToProjectUsageModel(item?: any): ProjectUsageModel {
    return <ProjectUsageModel>{
      text: item?.text ?? "",
      uniqueInstallCount: item?.uniqueInstallCount ?? 0,
      totalInstallCount: item?.totalInstallCount ?? 0,
    };
  }

  async getProjectUsageByTotal(
    projectId: string,
    from: string,
    to: string,
    developmentMode: boolean
  ): Promise<ResultModel<ProjectUsageModel[]>> {
    try {
      const result = await this.graphqlClient.query({
        query: GET_PROJECT_USAGE_BY_TOTAL_QUERY,
        variables: {
          projectId: projectId,
          from: from,
          to: to,
          developmentMode: developmentMode,
        },
      });

      const list: ProjectUsageModel[] = result?.data?.projectUsageByTotal?.map((item?: any) => {
        return this.mapToProjectUsageModel(item);
      });
      return <ResultModel<ProjectUsageModel[]>>{
        onSuccess: list,
      };
    } catch (error: any) {
      return <ResultModel<ProjectUsageModel[]>>{
        onError: error.message,
      };
    }
  }

  async getProjectUsageByRoute(
    projectId: string,
    from: string,
    to: string,
    developmentMode: boolean
  ): Promise<ResultModel<ProjectUsageModel[]>> {
    try {
      const result = await this.graphqlClient.query({
        query: GET_PROJECT_USAGE_BY_ROUTE_QUERY,
        variables: {
          projectId: projectId,
          from: from,
          to: to,
          developmentMode: developmentMode,
        },
      });

      const list: ProjectUsageModel[] = result?.data?.projectUsageByRoute?.map((item?: any) => {
        return this.mapToProjectUsageModel(item);
      });
      return <ResultModel<ProjectUsageModel[]>>{
        onSuccess: list,
      };
    } catch (error: any) {
      return <ResultModel<ProjectUsageModel[]>>{
        onError: error.message,
      };
    }
  }
}
