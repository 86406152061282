import React, { FC, useState } from "react";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/infrastructure/uikit/components/ui/dialog";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/infrastructure/uikit/components/ui/select";
import { Textarea } from "@/infrastructure/uikit/components/ui/textarea";
import { DropdownMenuItem } from "@/infrastructure/uikit/components/ui/dropdown-menu";
import { Pencil } from "lucide-react";
import { VariableModel, VariableTypeModel } from "../domain/model/VariableModel";
import { VariableViewModel } from "./VariableViewModel";

export type VariableUpdateViewProps = {
  frameId: string;
  variable: VariableModel;
  variableViewModel: VariableViewModel;
};

export const VariableUpdateView: FC<VariableUpdateViewProps> = (props: VariableUpdateViewProps) => {
  const [key, setKey] = useState(props.variable.key);
  const [value, setValue] = useState(props.variable.value);
  const [type, setType] = useState(props.variable.type);
  return (
    <Dialog>
      <DialogTrigger asChild>
        <DropdownMenuItem
          onSelect={(e) => {
            setKey(props.variable.key ?? "");
            setValue(props.variable.value ?? "");
            setType(props.variable.type ?? "");
            e.preventDefault();
          }}
        >
          <Pencil className="w-4 h-4 mx-2" />
          Edit
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle asChild>
            <span>Update {props.variable.key} variable</span>
          </DialogTitle>
          <DialogDescription>
            <div className="flex flex-col space-y-4 pt-4">
              <div className="flex flex-col gap-2">
                <Label className="sr-only" htmlFor="key">
                  key
                </Label>
                <Input
                  defaultValue={props.variable.key ?? ""}
                  id="key"
                  placeholder="Key"
                  type="text"
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                  onChange={(event: any) => setKey(event.target.value)}
                />
                <Textarea
                  defaultValue={props.variable.value ?? ""}
                  onChange={(event: any) => setValue(event.target.value)}
                  placeholder="Value"
                />
                <Select
                  value={props.variable.type ?? ""}
                  onValueChange={(value: string) => {
                    setType(value);
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder={"Select variable type"} />
                  </SelectTrigger>
                  <SelectContent>
                    {props.variableViewModel.uiState.variableTypes?.map((variableType: VariableTypeModel) => {
                      return (
                        <SelectItem key={variableType.id} value={variableType.id}>
                          <div className="flex flex-row">{variableType.text}</div>
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              onClick={() => {
                props.variableViewModel.uiAction.updateVariable(
                  props.frameId,
                  props.variable.id ?? "",
                  key ?? "",
                  value ?? "",
                  type ?? ""
                );
              }}
              variant={"default"}
              className="w-full"
            >
              Update
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
