import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { gql } from "@apollo/client";
import BlockRepository from "../../domain/BlockRepository";
import {
  BlockBreakpointModel,
  BlockDynamicPropertiesModel,
  BlockEventModel,
  BlockModel,
  BlockSlotModel,
} from "../../domain/model/BlockModel";

const ADD_BLOCK_MUTATION = gql`
  mutation addBlock($input: AddBlockInput!) {
    addBlock(input: $input) {
      id
    }
  }
`;
const DUPLICATE_BLOCK_MUTATION = gql`
  mutation duplicateBlock($input: DuplicateBlockInput!) {
    duplicateBlock(input: $input) {
      id
    }
  }
`;
const UPDATE_BLOCK_KEY_MUTATION = gql`
  mutation updateBlockKey($input: UpdateBlockKeyInput!) {
    updateBlockKey(input: $input) {
      id
    }
  }
`;
const MOVE_BLOCK_MUTATION = gql`
  mutation updateBlockPosition($input: UpdateBlockPositionInput!) {
    updateBlockPosition(input: $input) {
      id
    }
  }
`;

const UPDATE_BLOCK_PROPERTIES_MUTATION = gql`
  mutation updateBlockProperties($input: UpdateBlockPropertiesInput!) {
    updateBlockProperties(input: $input) {
      id
    }
  }
`;

const UPGRADE_BLOCK_MUTATION = gql`
  mutation upgradeBlock($input: UpgradeBlockInput!) {
    upgradeBlock(input: $input) {
      id
    }
  }
`;

const UPDATE_BLOCK_DATA_MUTATION = gql`
  mutation updateBlockData($input: UpdateBlockDataInput!) {
    updateBlockData(input: $input) {
      id
    }
  }
`;

const DELETE_BLOCK_MUTATION = gql`
  mutation deleteBlock($input: DeleteBlockInput!) {
    deleteBlock(input: $input) {
      id
    }
  }
`;

const GET_BLOCKS_QUERY = gql`
  query blocks($frameId: String!) {
    blocks(frameId: $frameId) {
      id
      parentId
      slot
      keyType
      key
      visibilityKey
      position
      integrationVersion
      properties {
        id
        key
        valueMobile
        valueTablet
        valueDesktop
        type
        description
        valuePicker
        valuePickerGroup
        valuePickerOptions
      }
      data {
        id
        key
        value
        type
      }
      events {
        id
        event
      }
      slots {
        id
        slot
      }
    }
  }
`;

const GET_BLOCK_QUERY = gql`
  query blockById($frameId: String!, $blockId: String!) {
    blockById(frameId: $frameId, blockId: $blockId) {
      id
      parentId
      slot
      keyType
      key
      visibilityKey
      position
      integrationVersion
      properties {
        id
        key
        valueMobile
        valueTablet
        valueDesktop
        type
        description
        valuePicker
        valuePickerGroup
        valuePickerOptions
      }
      data {
        id
        key
        value
        type
      }
      events {
        id
        event
      }
      slots {
        id
        slot
      }
    }
  }
`;

export class BlockRepositoryImpl implements BlockRepository {
  private readonly graphqlClient: any;

  constructor(graphqlClient: any) {
    this.graphqlClient = graphqlClient;
  }

  async addBlock(
    frameId: string,
    integrationId: string,
    parentId: string,
    key: string,
    position: number,
    slot: string
  ): Promise<ResultModel<BlockModel>> {
    try {
      await this.graphqlClient.mutate({
        mutation: ADD_BLOCK_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            integrationId: integrationId,
            parentId: parentId,
            key: key,
            visibilityKey: `${key}-visibility`,
            position: position,
            slot: slot
          },
        },
      });
      return <ResultModel<BlockModel>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<BlockModel>>{
        onError: error.message,
      };
    }
  }

  async duplicateBlock(frameId: string, originBlockId: string, key: string): Promise<ResultModel<BlockModel>> {
    try {
      await this.graphqlClient.mutate({
        mutation: DUPLICATE_BLOCK_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            originBlockId: originBlockId,
            key: key,
            visibilityKey: `${key}-visibility`,
          },
        },
      });
      return <ResultModel<BlockModel>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<BlockModel>>{
        onError: error.message,
      };
    }
  }

  async updateBlockKey(frameId: string, blockId: string, key: string): Promise<ResultModel<BlockModel>> {
    try {
      await this.graphqlClient.mutate({
        mutation: UPDATE_BLOCK_KEY_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            blockId: blockId,
            key: key,
            visibilityKey: `${key}-visibility`,
          },
        },
      });
      return <ResultModel<BlockModel>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<BlockModel>>{
        onError: error.message,
      };
    }
  }

  async moveBlock(frameId: string, blockId: string, parentId: string, position: number, slot: string): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: MOVE_BLOCK_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            blockId: blockId,
            parentId: parentId,
            position: position,
            slot: slot,
          },
        },
      });
      return <ResultModel<BlockModel>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<BlockModel>>{
        onError: error.message,
      };
    }
  }

  async updateBlockProperties(
    frameId: string,
    blockId: string,
    changeMap: Map<string, BlockBreakpointModel>
  ): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: UPDATE_BLOCK_PROPERTIES_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            blockId: blockId,
            properties: Array.from(changeMap.keys()).map((key) => {
              return {
                key: key,
                valueMobile: changeMap.get(key)?.valueMobile,
                valueTablet: changeMap.get(key)?.valueTablet,
                valueDesktop: changeMap.get(key)?.valueDesktop,
              };
            }),
          },
        },
      });
      return <ResultModel<BlockModel>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<BlockModel>>{
        onError: error.message,
      };
    }
  }

  async updateBlockData(frameId: string, blockId: string, changeMap: Map<string, string>): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: UPDATE_BLOCK_DATA_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            blockId: blockId,
            data: Array.from(changeMap.keys()).map((key) => {
              return {
                key: key,
                value: changeMap.get(key),
              };
            }),
          },
        },
      });
      return <ResultModel<BlockModel>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<BlockModel>>{
        onError: error.message,
      };
    }
  }

  async upgradeBlock(frameId: string, blockId: string, integrationId: string): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: UPGRADE_BLOCK_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            blockId: blockId,
            integrationId: integrationId,
          },
        },
      });
      return <ResultModel<any>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<any>>{
        onError: error.message,
      };
    }
  }

  async deleteBlock(frameId: string, blockId: string): Promise<ResultModel<BlockModel>> {
    try {
      await this.graphqlClient.mutate({
        mutation: DELETE_BLOCK_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            blockId: blockId,
          },
        },
      });
      return <ResultModel<BlockModel>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<BlockModel>>{
        onError: error.message,
      };
    }
  }

  async getBlocks(frameId: string): Promise<ResultModel<BlockModel[]>> {
    try {
      const result = await this.graphqlClient.query({
        query: GET_BLOCKS_QUERY,
        variables: { frameId: frameId },
      });

      const list: BlockModel[] = result?.data?.blocks?.map((item?: any) => {
        return this.blockToModel(item);
      });

      return <ResultModel<BlockModel[]>>{
        onSuccess: list,
      };
    } catch (error: any) {
      return <ResultModel<BlockModel[]>>{
        onError: error.message,
      };
    }
  }

  async getBlock(frameId: string, blockId: string): Promise<ResultModel<BlockModel>> {
    try {
      const result = await this.graphqlClient.query({
        query: GET_BLOCK_QUERY,
        variables: { frameId: frameId, blockId: blockId },
      });

      const block: BlockModel = this.blockToModel(result?.data?.blockById);
      return <ResultModel<BlockModel>>{
        onSuccess: block,
      };
    } catch (error: any) {
      return <ResultModel<BlockModel>>{
        onError: error.message,
      };
    }
  }

  private blockToModel(item: any): BlockModel {
    return {
      id: item?.id ?? "",
      parentId: item?.parentId ?? "",
      slot: item?.slot ?? "",
      name: item?.name ?? "",
      key: item?.key ?? "",
      keyType: item?.keyType ?? "",
      position: item?.position ?? 0,
      visibilityKey: item?.visibilityKey ?? "",
      data: new Map(
        item?.data?.map((dataItem: any) => {
          return [
            dataItem.key,
            {
              key: dataItem.key,
              value: dataItem.value,
              type: dataItem.type,
            },
          ];
        })
      ),
      properties: new Map(
        item?.properties
          ?.sort((a: any, b: any) => {
            if (a.valuePickerGroup > b.valuePickerGroup) {
              return -1;
            }
            if (a.valuePickerGroup < b.valuePickerGroup) {
              return 1;
            }
            return 0;
          })
          .map((property: any) => {
            return [property.key, this.blockDynamicPropertiesToModel(property)];
          })
      ),
      events: item?.events?.map((event: any) => {
        return {
          id: event?.id,
          event: event?.event,
        } as BlockEventModel;
      }),
      slots: item?.slots?.map((sl: any) => {
        return {
          id: sl?.id,
          slot: sl?.slot,
        } as BlockSlotModel;
      }),
      subBlocks: null,
      isParent: (item?.slots?.length ?? 0) > 0,
      integrationVersion: item?.integrationVersion,
    } as BlockModel;
  }

  private blockDynamicPropertiesToModel(item: any): BlockDynamicPropertiesModel {
    return {
      key: item.key,
      description: item.description,
      valueMobile: item.valueMobile,
      valueTablet: item.valueTablet,
      valueDesktop: item.valueDesktop,
      valuePicker: item.valuePicker,
      valuePickerGroup: item.valuePickerGroup,
      valuePickerOptions: item.valuePickerOptions,
      type: item.type,
    } as BlockDynamicPropertiesModel;
  }
}
