import { useState } from "react";
import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { useToast } from "@/infrastructure/uikit/components/ui/use-toast";
import { BlockBreakpointModel, BlockModel } from "../domain/model/BlockModel";
import { blockRepository } from "../di/BlockComponent";

export type BlockUiState = {
  blocks: BlockModel[] | undefined;
  blocksHierarchy: BlockModel[];
  activeBlock: BlockModel | undefined;
};

export type BlockUiAction = {
  getBlocks: (frameId: string) => void;
  addBlock: (frameId: string, integrationId: string, parentId: string, key: string, slot: string) => void;
  duplicateBlock: (frameId: string, originBlockId: string, key: string) => void;
  updateBlockKey: (frameId: string, blockId: string, key: string) => void;
  moveBlock: (frameId: string, blockId: string, parentId: string, position: number, slot: string) => void;
  deleteBlock: (frameId: string, blockId: string) => void;
  updateBlockProperties: (frameId: string, blockId: string, changeMap: Map<string, BlockBreakpointModel>) => void;
  updateBlockData: (frameId: string, blockId: string, changeMap: Map<string, string>) => void;
  upgradeBlock: (frameId: string, blockId: string, integrationId: string) => void;
  getBlock: (frameId: string, blockId: string) => void;
};

export type BlockViewModel = {
  uiState: BlockUiState;
  uiAction: BlockUiAction;
};

export const useBlockViewModel = () => {
  const [uiState, setUiState] = useState({} as BlockUiState);
  const { toast } = useToast();

  async function addBlock(frameId: string, integrationId: string, parentId: string, key: string, slot: string) {
    const result = await blockRepository.addBlock(frameId, integrationId, parentId, key, 0, slot);
    if (result.onSuccess) {
      await getBlocks(frameId);
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function duplicateBlock(frameId: string, originBlockId: string, key: string) {
    const result = await blockRepository.duplicateBlock(frameId, originBlockId, key);
    if (result.onSuccess) {
      await getBlocks(frameId);
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function updateBlockKey(frameId: string, blockId: string, key: string) {
    const result = await blockRepository.updateBlockKey(frameId, blockId, key);
    if (result.onSuccess) {
      await getBlocks(frameId);
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function moveBlock(frameId: string, blockId: string, parentId: string, position: number, slot: string) {
    const result = await blockRepository.moveBlock(frameId, blockId, parentId, position, slot);
    if (result.onSuccess) {
      await getBlocks(frameId);
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function deleteBlock(frameId: string, blockId: string) {
    const result = await blockRepository.deleteBlock(frameId, blockId);
    if (result.onSuccess) {
      await getBlocks(frameId);
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function getBlocks(frameId: string) {
    if (!frameId) return;
    const result: ResultModel<BlockModel[]> = await blockRepository.getBlocks(frameId);

    if (result.onSuccess) {
      setUiState((prevState) => {
        return {
          ...prevState,
          blocks: result.onSuccess,
          blocksHierarchy: buildTree(result.onSuccess ?? []),
        };
      });
    } else {
      setUiState({
        ...uiState,
        blocks: undefined,
      });
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function updateBlockProperties(frameId: string, blockId: string, changeMap: Map<string, BlockBreakpointModel>) {
    const result = await blockRepository.updateBlockProperties(frameId, blockId, changeMap);
    if (result.onSuccess) {
      getBlock(frameId, blockId);
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function updateBlockData(frameId: string, blockId: string, changeMap: Map<string, string>) {
    const result = await blockRepository.updateBlockData(frameId, blockId, changeMap);
    if (result.onSuccess) {
      getBlock(frameId, blockId);
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function upgradeBlock(frameId: string, blockId: string, integrationId: string) {
    const result = await blockRepository.upgradeBlock(frameId, blockId, integrationId);
    if (result.onSuccess) {
      getBlocks(frameId);
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function getBlock(frameId: string, blockId: string) {
    if (!blockId || !frameId) {
      setUiState((prevState) => {
        return {
          ...prevState,
          activeBlock: undefined,
        };
      });
      return;
    }
    const block = await blockRepository.getBlock(frameId, blockId);
    if (block.onSuccess) {
      setUiState((prevState) => {
        return {
          ...prevState,
          activeBlock: block.onSuccess,
        };
      });
    }
  }

  function findChildren(blocks: BlockModel[], parentId: string): BlockModel[] {
    return blocks
      .filter((block) => block.parentId === parentId)
      .map((block) => ({
        ...block,
        subBlocks: findChildren(blocks, block.id ?? ""),
      }));
  }

  function buildTree(blocks: BlockModel[]): BlockModel[] {
    const roots = blocks.filter((block) => block.parentId === "");
    return roots.map((root) => ({
      ...root,
      subBlocks: findChildren(blocks, root.id ?? ""),
    }));
  }

  return {
    uiState: uiState,
    uiAction: {
      getBlocks,
      addBlock,
      moveBlock,
      duplicateBlock,
      updateBlockKey,
      deleteBlock,
      updateBlockProperties,
      updateBlockData,
      upgradeBlock,
      getBlock,
    },
  } as BlockViewModel;
};
