import { BlockBreakpointModel, BlockDynamicPropertiesModel } from "@/feature/block/domain/model/BlockModel";
import { BlockViewModel } from "@/feature/block/presentation/BlockViewModel";
import { BlockDropdownPickerView } from "@/feature/block/presentation/picker/BlockDropdownPickerView";
import { BlockTextAreaPickerView } from "@/feature/block/presentation/picker/BlockTextAreaPickerView";
import { BlockTextFieldPickerView } from "@/feature/block/presentation/picker/BlockTextFieldPickerView";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";
import { Tabs, TabsList, TabsTrigger } from "@/infrastructure/uikit/components/ui/tabs";
import { Laptop2, RefreshCw, Smartphone, Tablet } from "lucide-react";
import React, { FC, useEffect, useState } from "react";
import { BlockComboboxPickerView } from "./picker/BlockComboboxPickerView";
import { BlockTogglePickerView } from "./picker/BlockTogglePickerView";

export type BlockPropertyViewProps = {
  blockViewModel: BlockViewModel;
  frameId: string;
  projectPlatform: string;
};

export const BlockPropertyView: FC<BlockPropertyViewProps> = (props: BlockPropertyViewProps) => {
  const [breakpoint, setBreakpoint] = useState(props.projectPlatform === "REACT" ? "DESKTOP" : "MOBILE");
  const [properties, setProperties] = useState<Map<string, BlockDynamicPropertiesModel> | null>();
  const changeMap = new Map<string, BlockBreakpointModel>();

  useEffect(() => {
    setProperties(props.blockViewModel.uiState.activeBlock?.properties);
  }, [props.blockViewModel.uiState.activeBlock, breakpoint]);

  useEffect(() => {
    changeMap.clear();
  }, [breakpoint]);

  if (!props.blockViewModel.uiState.activeBlock?.id) {
    return <></>;
  }

  return (
    <>
      <ScrollArea>
        <Button
          variant={"default"}
          className="w-full"
          onClick={() => {
            props.blockViewModel.uiAction.updateBlockProperties(
              props.frameId,
              props.blockViewModel.uiState.activeBlock?.id ?? "",
              changeMap
            );
            setBreakpoint(breakpoint);
          }}
        >
          <RefreshCw className="h-4 w-4 me-2" />
          Update properties
        </Button>
        <div className={"pt-4"}>
          <Tabs
            hidden={!props.blockViewModel.uiState.activeBlock?.id}
            defaultValue={breakpoint}
            className="flex flex-row justify-center"
            onValueChange={(value: string) => {
              setBreakpoint(value);
            }}
          >
            <TabsList className="w-full">
              <TabsTrigger value="MOBILE" className="w-full">
                <Smartphone className={"w-4 h-4"} />
              </TabsTrigger>
              <TabsTrigger value="TABLET" className="w-full">
                <Tablet className={"w-4 h-4"} />
              </TabsTrigger>
              <TabsTrigger value="DESKTOP" className="w-full">
                <Laptop2 className={"w-4 h-4"} />
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
        <div className={"flex flex-col gap-2 pt-4"}>
          {Array.from(properties?.keys() ?? []).map((key) => {
            const property = properties?.get(key);
            if (!property) return <></>;

            if (breakpoint === "MOBILE") {
              return (
                <BlockPickerView
                  key={property.key + breakpoint + props.blockViewModel.uiState.activeBlock?.id + Math.random().toString()}
                  property={property}
                  value={changeMap.get(property.key ?? "")?.valueMobile ?? property?.valueMobile ?? ""}
                  onChange={(key, newValue) => {
                    if (props.projectPlatform === "ANDROID" || props.projectPlatform === "IOS") {
                      changeMap.set(key, {
                        valueDesktop: newValue,
                        valueTablet: newValue,
                        valueMobile: newValue,
                      });
                    } else {
                      changeMap.set(key, {
                        valueMobile: newValue,
                      });
                    }
                  }}
                />
              );
            } else if (breakpoint === "TABLET") {
              return (
                <BlockPickerView
                  key={property.key + breakpoint + props.blockViewModel.uiState.activeBlock?.id + Math.random().toString()}
                  property={property}
                  value={changeMap.get(property.key ?? "")?.valueTablet ?? property?.valueTablet ?? ""}
                  onChange={(key, newValue) => {
                    changeMap.set(key, {
                      valueTablet: newValue,
                    });
                  }}
                />
              );
            } else if (breakpoint === "DESKTOP") {
              return (
                <BlockPickerView
                  key={property.key + breakpoint + props.blockViewModel.uiState.activeBlock?.id + Math.random().toString()}
                  property={property}
                  value={changeMap.get(property.key ?? "")?.valueDesktop ?? property?.valueDesktop ?? ""}
                  onChange={(key, newValue) => {
                    if (props.projectPlatform === "REACT") {
                      changeMap.set(key, {
                        valueDesktop: newValue,
                        valueTablet: newValue,
                        valueMobile: newValue,
                      });
                    } else {
                      changeMap.set(key, {
                        valueDesktop: newValue,
                      });
                    }
                  }}
                />
              );
            } else {
              return <></>;
            }
          })}
        </div>
      </ScrollArea>
    </>
  );
};

type BlockPickerViewProps = {
  property: BlockDynamicPropertiesModel;
  value: string;
  onChange: (key: string, newValue: string) => void;
};
const BlockPickerView: FC<BlockPickerViewProps> = (props: BlockPickerViewProps) => {
  if (props.property.valuePicker === "text-input") {
    return <BlockTextFieldPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  } else if (props.property.valuePicker === "text-area-input") {
    return <BlockTextAreaPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  } else if (props.property.valuePicker === "number-input") {
    return <BlockTextFieldPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  } else if (props.property.valuePicker === "color-picker") {
    return <BlockTextFieldPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  } else if (props.property.valuePicker === "slider") {
    return <BlockTextFieldPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  } else if (props.property.valuePicker === "combobox-input") {
    return <BlockComboboxPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  } else if (props.property.valuePicker === "dropdown") {
    return <BlockDropdownPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  } else if (props.property.valuePicker === "toggle") {
    return <BlockTogglePickerView property={props.property} value={props.value} onChange={props.onChange} />;
  } else {
    return <BlockTextFieldPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  }
};
