import { Alert, AlertDescription } from "@/infrastructure/uikit/components/ui/alert";
import { Avatar, AvatarImage } from "@/infrastructure/uikit/components/ui/avatar";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/infrastructure/uikit/components/ui/dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/infrastructure/uikit/components/ui/drawer";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/infrastructure/uikit/components/ui/dropdown-menu";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import { MarkdownRenderer } from "@/infrastructure/uikit/components/ui/markdownRenderer";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/infrastructure/uikit/components/ui/select";
import { KeyRound, MoreVertical, Pencil, Trash2, X } from "lucide-react";
import React, { FC, useEffect, useState } from "react";
import Confetti from "react-confetti";
import { ProjectModel } from "../domain/model/ProjectModel";
import { ProjectPlatformModel } from "../domain/model/ProjectPlatformModel";
import { ProjectApiKeyView } from "./ProjectApiKeyView";
import { useProjectViewModel } from "./ProjectViewModel";

export type ProjectViewProps = {
  organizationId: string;
};

export const ProjectView: FC<ProjectViewProps> = (props: ProjectViewProps) => {
  const projectViewModel = useProjectViewModel();
  const [name, setName] = useState("");
  const [platformId, setPlatformId] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [isCreated, setIsCreated] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    projectViewModel.uiAction.getProjects(props.organizationId);
  }, [props.organizationId]);

  useEffect(() => {
    setShowConfetti(isCreated);
    const timer1 = setTimeout(() => {
      setIsCreated(false);
    }, 5 * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, [projectViewModel.uiState.projects?.length && isCreated]);

  return (
    <>
      <div className="flex flex-col w-full p-4 bg-muted">
        <div className="flex flex-row w-full pb-4 justify-end">
          <Dialog>
            <DialogTrigger asChild>
              <Button variant={"default"}>Create a new project</Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Create a new project</DialogTitle>
                <DialogDescription className="pt-4 space-y-4">
                  <Input
                    value={name}
                    className="w-full"
                    type="text"
                    autoComplete="off"
                    placeholder="Name"
                    onChange={(event: any) => setName(event.target.value)}
                  />
                  <Select
                    onValueChange={(value: string) => {
                      setPlatformId(value);
                    }}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder={"Select platform"} />
                    </SelectTrigger>
                    <SelectContent>
                      {projectViewModel.uiState.platforms?.map((platform: ProjectPlatformModel) => {
                        return (
                          <SelectItem disabled={!platform.enable} key={platform.id} value={platform.id}>
                            <div className="flex flex-row">
                              <img className="h-4 w-4 mx-2" src={platform.icon} alt="logo" />
                              {platform.name}
                            </div>
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                </DialogDescription>
              </DialogHeader>
              <DialogFooter className="sm:justify-start">
                <DialogClose asChild>
                  <Button
                    className="w-full"
                    variant={"default"}
                    onClick={() => {
                      setName("");
                      projectViewModel.uiAction.createProject(props.organizationId, name, platformId);
                      setIsCreated(true);
                    }}
                  >
                    Create
                  </Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
          {projectViewModel.uiState.projects?.map((project: ProjectModel) => {
            return (
              <div key={project.id} className="flex flex-col gap-4 p-4 rounded-lg bg-card w-full space-y-4">
                <div className="flex flex-row space-x-2">
                  <Avatar
                    onClick={() => {
                      projectViewModel.uiAction.openWorkspace(project);
                    }}
                  >
                    <div className="rounded-full bg-muted p-2">
                      <AvatarImage src={project.image} alt={project.name} />
                    </div>
                  </Avatar>
                  <div className="flex items-center justify-between text-card-foreground w-full">
                    <h2
                      onClick={() => {
                        projectViewModel.uiAction.openWorkspace(project);
                      }}
                      className="text-lg font-medium truncate cursor-pointer"
                    >
                      {project.name}
                    </h2>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <MoreVertical className="h-4 w-4" />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <Dialog>
                          <DialogTrigger asChild>
                            <DropdownMenuItem
                              onSelect={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Trash2 className="w-4 h-4 mx-2" />
                              Delete {project.name}
                            </DropdownMenuItem>
                          </DialogTrigger>
                          <DialogContent>
                            <DialogHeader>
                              <DialogTitle>{`Are you sure to delete '${project.name}' project?`}</DialogTitle>
                              <DialogDescription>
                                <div className="flex flex-col space-y-4 pt-4">
                                  <div className="flex flex-col gap-2">
                                    <Alert variant="destructive">
                                      <AlertDescription>
                                        Please note that deleting the project will also delete any related frames,
                                        flows, blocks, actions or localizations. To confirm the deletion please type the
                                        name of the project. This action cannot be undone.
                                      </AlertDescription>
                                    </Alert>
                                  </div>
                                  <Label className="sr-only" htmlFor="name">
                                    Name
                                  </Label>
                                  <Input
                                    id="name"
                                    placeholder="name"
                                    type="text"
                                    autoCapitalize="none"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    onChange={(event: any) => setDeleteName(event.target.value)}
                                  />
                                </div>
                              </DialogDescription>
                            </DialogHeader>
                            <DialogClose asChild={true}>
                              <Button
                                onClick={() => {
                                  projectViewModel.uiAction.deleteProject(
                                    project.name,
                                    project.id,
                                    props.organizationId
                                  );
                                }}
                                disabled={deleteName !== project.name}
                                variant={"destructive"}
                                className="w-full"
                              >
                                Delete
                              </Button>
                            </DialogClose>
                          </DialogContent>
                        </Dialog>
                        <Dialog>
                          <DialogTrigger asChild>
                            <DropdownMenuItem
                              onSelect={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Pencil className="w-4 h-4 mx-2" />
                              Rename {project.name}
                            </DropdownMenuItem>
                          </DialogTrigger>
                          <DialogContent>
                            <DialogHeader>
                              <DialogTitle>{`Do you want to rename '${project.name}'`}</DialogTitle>
                              <DialogDescription>
                                <div className="flex flex-col space-y-4 pt-4">
                                  <Label className="sr-only" htmlFor="name">
                                    Name
                                  </Label>
                                  <Input
                                    id="name"
                                    placeholder="name"
                                    type="text"
                                    autoCapitalize="none"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    onChange={(event: any) => setUpdateName(event.target.value)}
                                  />
                                </div>
                              </DialogDescription>
                            </DialogHeader>
                            <DialogClose asChild={true}>
                              <Button
                                onClick={() => {
                                  projectViewModel.uiAction.updateProject(updateName, project.id, props.organizationId);
                                }}
                                className="w-full"
                              >
                                Update
                              </Button>
                            </DialogClose>
                          </DialogContent>
                        </Dialog>
                        <Drawer dismissible={false}>
                          <DrawerTrigger asChild={true}>
                            <DropdownMenuItem
                              onSelect={(e) => {
                                projectViewModel.uiAction.openProjectDocumentation(project);
                                e.preventDefault();
                              }}
                            >
                              <KeyRound className="w-4 h-4 mx-2" />
                              Api keys
                            </DropdownMenuItem>
                          </DrawerTrigger>
                          <DrawerContent className="h-screen">
                            <DrawerHeader>
                              <div className="flex flex-row justify-between items-center">
                                <DrawerTitle>Api keys</DrawerTitle>
                                <DrawerClose>
                                  <Button variant={"ghost"} size={"icon"}>
                                    <X />
                                  </Button>
                                </DrawerClose>
                              </div>
                              <DrawerDescription>
                                <ProjectApiKeyView projectViewModel={projectViewModel} projectId={project.id} />
                              </DrawerDescription>
                            </DrawerHeader>
                          </DrawerContent>
                        </Drawer>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
                <div className="flex flex-row space-x-2">
                  <Button
                    className="w-full"
                    variant={"outline"}
                    onClick={() => {
                      projectViewModel.uiAction.openWorkspace(project);
                    }}
                  >
                    Workspace
                  </Button>
                  <Button
                    className="w-full"
                    variant={"outline"}
                    onClick={() => {
                      projectViewModel.uiAction.openProjectUsage(project.id);
                    }}
                  >
                    Usage
                  </Button>
                  <Drawer dismissible={false}>
                    <DrawerTrigger asChild={true}>
                      <Button className="w-full" variant={"outline"}>
                        Quick setup
                      </Button>
                    </DrawerTrigger>
                    <DrawerContent>
                      <DrawerHeader>
                        <div className="flex flex-row justify-between items-center">
                          <DrawerTitle>{project.name}</DrawerTitle>
                          <DrawerClose>
                            <Button variant={"ghost"} size={"icon"}>
                              <X />
                            </Button>
                          </DrawerClose>
                        </div>
                        <DrawerDescription>
                          <MarkdownRenderer>{project.doc}</MarkdownRenderer>
                          <Button
                            onClick={() => {
                              projectViewModel.uiAction.copyApiKey(project.apiKeys[0].apiKey);
                            }}
                            className="w-full"
                            variant={"outline"}
                          >
                            Copy API key
                          </Button>
                        </DrawerDescription>
                      </DrawerHeader>
                    </DrawerContent>
                  </Drawer>
                </div>
              </div>
            );
          })}
        </div>
        {showConfetti ? (
          <Confetti
            numberOfPieces={500}
            gravity={0.06}
            recycle={false}
            width={window.innerWidth}
            height={window.innerHeight}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
