import React, { FC } from "react";

type DevicePreviewViewProps = {
  device: string;
  children: React.ReactNode;
};

export const DevicePreviewView: FC<DevicePreviewViewProps> = (props: DevicePreviewViewProps) => {
  if (props.device === "MOBILE") {
    return <PhoneDevice>{props.children}</PhoneDevice>;
  } else if (props.device === "TABLET") {
    return <TabletDevice>{props.children}</TabletDevice>;
  } else if (props.device === "DESKTOP") {
    return <DesktopDevice>{props.children}</DesktopDevice>;
  } else {
    return <></>;
  }
};

type DeviceTypePreviewViewProps = {
  children: React.ReactNode;
};

const PhoneDevice: FC<DeviceTypePreviewViewProps> = (props: DeviceTypePreviewViewProps) => {
  return (
    <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[700px] w-[360px]">
      <div className="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
      <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
      <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
      <div className="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
      <div className="rounded-[2rem] overflow-hidden w-[332px] h-[672px] bg-white dark:bg-gray-800">
        {props.children}
      </div>
    </div>
  );
};
const TabletDevice: FC<DeviceTypePreviewViewProps> = (props: DeviceTypePreviewViewProps) => {
  return (
    <div className="border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[682px] max-w-[912px]">
      <div className="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
      <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
      <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
      <div className="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
      <div className="rounded-[2rem] overflow-hidden w-[880px] h-[654px] bg-white dark:bg-gray-800">
        {props.children}
      </div>
    </div>
  );
};
const DesktopDevice: FC<DeviceTypePreviewViewProps> = (props: DeviceTypePreviewViewProps) => {
  return (
    <div className="border-gray-800 dark:border-gray-800 bg-gray-800 border-[8px] rounded-xl h-[720px] max-w-[1224px]">
      <div className="rounded-lg overflow-hidden w-[1200px] h-[702px] bg-white dark:bg-gray-800">{props.children}</div>
    </div>
  );
};
