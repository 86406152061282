import { BlockDynamicPropertiesModel } from "@/feature/block/domain/model/BlockModel";
import { Combobox } from "@/infrastructure/uikit/components/ui/combobox";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import React, { useEffect, useState } from "react";

type BlockDropdownPickerViewProps = {
  property: BlockDynamicPropertiesModel;
  value: string;
  onChange: (key: string, newValue: string) => void;
};

export const BlockDropdownPickerView = (props: BlockDropdownPickerViewProps) => {
  const [value, setValue] = useState(props.value);
  const options = JSON.parse(props.property.valuePickerOptions ?? "") ?? [];
  useEffect(() => {
    setValue(
      options?.find((opt: any) => {
        return opt?.id === props.value;
      })?.id ?? props.value
    );
  }, []);
  return (
    <>
      <div
        key={props.property.key + Math.random().toString()}
        className="grid w-full items-center gap-1.5 bg-muted p-2 rounded-lg"
      >
        <Label
          key={props.property.key + Math.random().toString()}
          title={props.property.description ?? ""}
          htmlFor={"blockComboboxPicker_" + props.property.key}
        >
          {props.property.key}
        </Label>
        <Combobox
          key={props.property.key + Math.random().toString()}
          list={options}
          defaultValue={value}
          onSelect={(id: string) => {
            setValue(id);
            props.onChange(props.property.key ?? "", id);
          }}
        />
      </div>
    </>
  );
};
