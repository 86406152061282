import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { VariableRepository } from "../../domain/VariableRepository";
import { VariableModel } from "../../domain/model/VariableModel";
import {
  ADD_VARIABLE_MUTATION,
  DELETE_VARIABLE_MUTATION,
  GET_VARIABLE_QUERY,
  UPDATE_VARIABLE_MUTATION,
} from "../VariableQuery";

export class VariableRepositoryImpl implements VariableRepository {
  private readonly graphqlClient: any;

  constructor(graphqlClient: any) {
    this.graphqlClient = graphqlClient;
  }

  async addVariable(frameId: string, key: string, value: string, type: string): Promise<ResultModel<string>> {
    try {
      const result = await this.graphqlClient.mutate({
        mutation: ADD_VARIABLE_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            key: key,
            value: value,
            type: type,
          },
        },
      });

      const resultKey: string = result?.data?.addVariable?.key;
      return {
        onSuccess: resultKey,
      } as ResultModel<string>;
    } catch (error: any) {
      return {
        onError: error.message,
      } as ResultModel<string>;
    }
  }

  async updateVariable(
    frameId: string,
    id: string,
    key: string,
    value: string,
    type: string
  ): Promise<ResultModel<string>> {
    try {
      const result = await this.graphqlClient.mutate({
        mutation: UPDATE_VARIABLE_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            id: id,
            key: key,
            value: value,
            type: type,
          },
        },
      });

      const resultKey: string = result?.data?.updateVariable?.key;
      return {
        onSuccess: resultKey,
      } as ResultModel<string>;
    } catch (error: any) {
      return {
        onError: error.message,
      } as ResultModel<string>;
    }
  }

  async deleteVariable(frameId: string, key: string): Promise<ResultModel<string>> {
    try {
      const result = await this.graphqlClient.mutate({
        mutation: DELETE_VARIABLE_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            key: key,
          },
        },
      });

      const resultKey: string = result?.data?.deleteVariable?.key;
      return {
        onSuccess: resultKey,
      } as ResultModel<string>;
    } catch (error: any) {
      return {
        onError: error.message,
      } as ResultModel<string>;
    }
  }

  async getVariables(frameId: string): Promise<ResultModel<VariableModel[]>> {
    try {
      const result = await this.graphqlClient.query({
        query: GET_VARIABLE_QUERY,
        variables: { frameId: frameId },
      });

      const list: VariableModel[] = result?.data?.variables?.map((item?: any) => {
        return this.variableToModel(item);
      });

      return <ResultModel<VariableModel[]>>{
        onSuccess: list,
      };
    } catch (error: any) {
      return <ResultModel<VariableModel[]>>{
        onError: error.message,
      };
    }
  }

  private variableToModel(variable: any): VariableModel {
    return <VariableModel>{
      id: variable.id ?? "",
      key: variable.key ?? "",
      value: variable.value ?? "",
      type: variable.type ?? "",
    };
  }
}
