import React, { useState } from "react";

import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/infrastructure/uikit/components/ui/card";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { useUrlQueryParams } from "@/infrastructure/utility/NavigationUitl";

export function WorkspaceSetting() {
  const navigate = useNavigate();
  const query = useUrlQueryParams();
  
  const projectId = query.get("projectId") ?? "";
  const [url, setUrl] = useState(localStorage.getItem(`PREVIEW_URL_${projectId}`) ?? "");

  return (
    <div className="flex flex-col w-full bg-background h-full">
      <div className="flex flex-row w-full justify-between items-center h-16 bg-card shadow-md px-4">
        <div className="flex flex-row items-center space-x-2">
          <Button
            onClick={() => {
              navigate(-1);
            }}
            variant="ghost"
            size="icon"
            className="rounded-full"
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <p>Setting</p>
        </div>
      </div>
      <div className="flex flex-col w-full p-4">
        <Card>
          <CardHeader>
            <CardTitle>Preview url</CardTitle>
            <CardDescription>
              Please provide a link for the preview, for react project you can just use the actual webapp url, for
              android and iOS please use device streaming
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Input
              defaultValue={url}
              placeholder="https://someurl.com"
              type="text"
              autoCapitalize="none"
              autoComplete="off"
              autoCorrect="off"
              onChange={(event: any) => setUrl(event.target.value)}
            />
          </CardContent>
          <CardFooter className="border-t px-6 py-4">
            <Button
              onClick={() => {
                localStorage.setItem(`PREVIEW_URL_${projectId}`, url);
              }}
            >
              Save
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}
