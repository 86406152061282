import { useWorkspaceState } from "@/coordinator/workspace/state/WorkspaceContext";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/infrastructure/uikit/components/ui/dropdown-menu";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/infrastructure/uikit/components/ui/table";
import { useUrlQueryParams } from "@/infrastructure/utility/NavigationUitl";
import { ArrowLeft, MoreHorizontal, Search } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VariableModel } from "../domain/model/VariableModel";
import { VariableCreateView } from "./VariableCreateView";
import { VariableDeleteView } from "./VariableDeleteView";
import { VariableUpdateView } from "./VariableUpdateView";

export const VariableView = () => {
  const query = useUrlQueryParams();
  const navigate = useNavigate();

  const { variableViewModel, frameViewModel } = useWorkspaceState();

  const [search] = useState(variableViewModel.uiState.searchQuery);

  useEffect(() => {
    variableViewModel.uiAction.getVariables(query.get("frameId") ?? "");
  }, [query.get("frameId")]);

  return (
    <>
      <div className="flex flex-col w-full bg-background h-full">
        <div className="flex flex-row w-full justify-between items-center h-16 bg-card shadow-md px-4">
          <div className="flex flex-row items-center space-x-2">
            <Button
              onClick={() => {
                navigate(-1);
              }}
              variant="ghost"
              size="icon"
              className="rounded-full"
            >
              <ArrowLeft className="h-4 w-4" />
            </Button>
            <p>Variable of {frameViewModel.uiState.selectedFrame?.name ?? ""}</p>
          </div>
        </div>
        <ScrollArea className="flex flex-col w-full h-full p-4">
          <div className="flex flex-row p-1 space-x-4 justify-between">
            <div>
              <div className="relative flex items-center max-w-2xl ">
                <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 transform" />
                <Input
                  defaultValue={search}
                  type="text"
                  autoComplete="off"
                  placeholder="Variable key"
                  className="w-96 pl-8"
                  onChange={(event: any) => {
                    variableViewModel.uiAction.search(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className={"space-x-2 flex flex-row justify-end"}>
              <VariableCreateView frameId={query.get("frameId") ?? ""} variableViewModel={variableViewModel} />
            </div>
          </div>
          <div className="space-y-2">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Key</TableHead>
                  <TableHead className="truncate max-w-96">Value</TableHead>
                  <TableHead>Type</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {variableViewModel.uiState.variables?.map((variable: VariableModel) => {
                  return (
                    <TableRow key={variable.key}>
                      <TableCell>{variable.key}</TableCell>
                      <TableCell className="truncate max-w-96">{variable.value}</TableCell>
                      <TableCell>{variable.type}</TableCell>
                      <TableCell>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant={"ghost"} size={"icon"}>
                              <MoreHorizontal />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            <VariableUpdateView
                              frameId={query.get("frameId") ?? ""}
                              variable={variable}
                              variableViewModel={variableViewModel}
                            />
                            <VariableDeleteView
                              frameId={query.get("frameId") ?? ""}
                              variable={variable}
                              variableViewModel={variableViewModel}
                            />
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </ScrollArea>
      </div>
    </>
  );
};
