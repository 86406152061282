import {Button} from "@/infrastructure/uikit/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/infrastructure/uikit/components/ui/dialog";
import React, {FC, useState} from "react";
import {ActionViewModel} from "@/feature/action/presentation/ActionViewModel";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/infrastructure/uikit/components/ui/select";

export type ActionCreateViewProps = {
  actionViewModel: ActionViewModel
  frameId: string
  blockKey: string
  blockEvents: string[]
};

export const ActionCreateView: FC<ActionCreateViewProps> = (
  props: ActionCreateViewProps
) => {
  const [event, setEvent] = useState("");
  return (
    <Dialog>
      <DialogTrigger asChild={true}>
        <Button variant={"default"} className="w-full">
          Add a new action
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle asChild>
            <span>Add a new action</span>
          </DialogTitle>
          <DialogDescription>
            <div className="flex flex-col space-y-4 pt-4">
              <div className="flex flex-col gap-2">
                <Select
                  onValueChange={(value: string) => {
                    setEvent(value);
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder={"Select event"}/>
                  </SelectTrigger>
                  <SelectContent>
                    {props.blockEvents?.map((event: string) => {
                        return (
                          <SelectItem
                            key={event}
                            value={event}
                          >
                            <div className="flex flex-row">
                              {event}
                            </div>
                          </SelectItem>
                        );
                      }
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              onClick={() => {
                props.actionViewModel.uiAction.addAction(props.frameId, props.blockKey, event)
              }}
              variant={"default"}
              className="w-full"
            >
              Add
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
