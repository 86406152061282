import React, { FC, useEffect } from "react";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";

import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/infrastructure/uikit/components/ui/chart";

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/infrastructure/uikit/components/ui/card";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/infrastructure/uikit/components/ui/select";

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/infrastructure/uikit/components/ui/tooltip";

import { Label } from "@/infrastructure/uikit/components/ui/label";
import { Switch } from "@/infrastructure/uikit/components/ui/switch";

import { useProjectUsageViewModel } from "./ProjectUsageViewModel";

import { Button } from "@/infrastructure/uikit/components/ui/button";
import { ArrowLeft, CircleHelp } from "lucide-react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";

const ProjectUsageChartConfig = {
  totalInstallCount: {
    label: "total",
    color: "#2563eb",
  },
  uniqueInstallCount: {
    label: "unique",
    color: "#60a5fa",
  },
} as ChartConfig;

export const ProjectUsageView: FC = () => {
  const params = useParams();
  const { uiState, uiAction } = useProjectUsageViewModel();

  useEffect(() => {
    uiAction.getProjectUsages(params.id ?? "");
  }, [params.id]);

  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col w-full bg-background h-full">
        <div className="flex flex-row w-full justify-between items-center h-16 bg-card shadow-md px-4 z-10">
          <div className="flex flex-row items-center space-x-2">
            <Button
              onClick={() => {
                navigate(-1);
              }}
              variant="ghost"
              size="icon"
              className="rounded-full"
            >
              <ArrowLeft className="h-4 w-4" />
            </Button>
            <p>Project usage</p>
          </div>
        </div>
        <ScrollArea className="flex flex-col w-full h-full">
          <div className="max-w-7xl mx-auto p-4">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center space-x-2">
                <Label htmlFor="development-mode">Development Mode</Label>
                <Switch
                  id="development-mode"
                  checked={uiState.projectUsagesDevelopment}
                  onCheckedChange={(checked: boolean) => uiAction.changeProjectUsagesDevelopment(checked)}
                />
              </div>
              <div>
                <Select
                  defaultValue="7"
                  onValueChange={(value: string) => {
                    if (Number(value)) {
                      uiAction.changeTimeFrameRange(Number(value));
                    } else {
                      /* empty */
                    }
                  }}
                >
                  <SelectTrigger className="w-[160px] rounded-lg sm:ml-auto" aria-label="Select a Timeframe">
                    <SelectValue placeholder="Select a Timeframe" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="7" className="rounded-lg">
                        Last 7 days
                      </SelectItem>
                      <SelectItem value="14" className="rounded-lg">
                        Last 14 days
                      </SelectItem>
                      <SelectItem value="30" className="rounded-lg">
                        Last 30 days
                      </SelectItem>
                      <SelectItem value="90" className="rounded-lg">
                        Last 90 days
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
              <Card>
                <CardHeader>
                  <CardTitle>Unique usage</CardTitle>
                  <CardDescription className="flex flex-row gap-2">
                    {uiState.dataRangeTitle}
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <CircleHelp className="w-4 h-4" />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Number of distinct users who used the app {uiState.dataRangeTitle}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </CardDescription>
                </CardHeader>
                <CardContent className="grad gap-4">
                  <p className="flex items-baseline text-2xl font-semibold text-gray-900">
                    {uiState.totalInstallCount}
                  </p>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle>Total usage</CardTitle>
                  <CardDescription className="flex flex-row gap-2">
                    {uiState.dataRangeTitle}
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <CircleHelp className="w-4 h-4" />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Total number of usage, including repeats {uiState.dataRangeTitle}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <p className="text-2xl font-semibold text-gray-900">{uiState.totalUsageCount}</p>
                </CardContent>
              </Card>
            </div>
            <Card className="mb-4">
              <CardHeader>
                <CardTitle>Active usage</CardTitle>
                <CardDescription>{uiState.dataRangeTitle}</CardDescription>
              </CardHeader>
              <CardContent>
                <ChartContainer config={ProjectUsageChartConfig} className="h-[200px] w-full">
                  <BarChart accessibilityLayer data={uiState.projectUsagesTotal}>
                    <CartesianGrid vertical={false} />
                    <XAxis
                      dataKey="text"
                      tickLine={false}
                      tickMargin={10}
                      axisLine={false}
                      tickFormatter={(value) => value}
                    />
                    <ChartTooltip content={<ChartTooltipContent />} />
                    <ChartLegend content={<ChartLegendContent />} />
                    <Bar dataKey="totalInstallCount" fill="var(--color-totalInstallCount)" radius={4} />
                    <Bar dataKey="uniqueInstallCount" fill="var(--color-uniqueInstallCount)" radius={4} />
                  </BarChart>
                </ChartContainer>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Usage per route</CardTitle>
                <CardDescription>{uiState.dataRangeTitle}</CardDescription>
              </CardHeader>
              <CardContent>
                <ChartContainer config={ProjectUsageChartConfig} className="h-[200px] w-full">
                  <BarChart layout="vertical" accessibilityLayer data={uiState.projectUsagesRoute}>
                    <CartesianGrid horizontal={false} />
                    <XAxis
                      type="number"
                      tickLine={false}
                      tickMargin={5}
                      axisLine={false}
                      tickFormatter={(value) => value}
                    />
                    <YAxis
                      type="category"
                      dataKey="text"
                      tickLine={false}
                      tickMargin={5}
                      axisLine={false}
                      tickFormatter={(value) => value}
                    />
                    <ChartTooltip content={<ChartTooltipContent />} />
                    <ChartLegend content={<ChartLegendContent />} />
                    <Bar dataKey="totalInstallCount" fill="var(--color-totalInstallCount)" radius={4} />
                    <Bar dataKey="uniqueInstallCount" fill="var(--color-uniqueInstallCount)" radius={4} />
                  </BarChart>
                </ChartContainer>
              </CardContent>
            </Card>
          </div>
        </ScrollArea>
      </div>
    </>
  );
};
