import {Button} from "@/infrastructure/uikit/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/infrastructure/uikit/components/ui/dialog";
import {Shapes} from "lucide-react";
import React, {FC, useEffect, useState} from "react";
import {IntegrationViewModel} from "./IntegrationViewModel";

export type IntegrationInstallUpgradeUninstallViewProps = {
  integrationViewModel: IntegrationViewModel;
  organizationId: string;
  projectId: string;
  integrationId: string;
  integrationName: string;
};

export const InstallUpgradeUninstallView: FC<
  IntegrationInstallUpgradeUninstallViewProps
> = (props: IntegrationInstallUpgradeUninstallViewProps) => {
  const [action, setAction] = useState("");

  useEffect(() => {
    if (props.integrationViewModel.uiState.integrationProject?.hasUpdate === true) {
      setAction("Upgrade");
    } else if (props.integrationViewModel.uiState.integrationProject?.id) {
      setAction("UnInstall");
    } else {
      setAction("Install");
    }
  }, [props.integrationViewModel.uiState.integrationProject]);

  return (
    <Dialog>
      <DialogTrigger>
        <Button variant={"default"}>
          <Shapes className="w-4 h-4 mx-2"/>
          {action}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle asChild>
            <span>Confirm</span>
          </DialogTitle>
          <DialogDescription>
            <div className="flex flex-col space-y-4 pt-4">
              <div className="flex flex-col gap-2">
                {`Are you sure to ${action} '${props.integrationName}'?`}
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              onClick={() => {
                props.integrationViewModel.uiAction.upgradeOrInstallOrUnInstall(
                  props.organizationId,
                  props.projectId,
                  props.integrationId
                );
              }}
              variant={"default"}
              className="w-full"
            >
              {action}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
