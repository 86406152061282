import { BlockDataModel } from "@/feature/block/domain/model/BlockModel";
import { BlockViewModel } from "@/feature/block/presentation/BlockViewModel";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import { Combobox } from "@/infrastructure/uikit/components/ui/combobox";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";
import { RefreshCw } from "lucide-react";
import React, { FC, useEffect, useState } from "react";

export type BlockDataViewProps = {
  frameId: string;
  variables: { id: string; text: string }[];
  blockViewModel: BlockViewModel;
};

export const BlockDataView: FC<BlockDataViewProps> = (props: BlockDataViewProps) => {
  const [data, setData] = useState<Map<string, BlockDataModel> | null>();
  const changeMap = new Map<string, string>();

  useEffect(() => {
    setData(props.blockViewModel.uiState.activeBlock?.data ?? new Map());
  }, [props.frameId, props.blockViewModel.uiState.activeBlock]);

  const visibilityVariable = props.variables?.find(
    (variable) => variable.id === props.blockViewModel.uiState.activeBlock?.visibilityKey
  );

  return (
    <>
      <ScrollArea>
        {data?.size ?? 0 > 0 ? (
          <Button
            variant={"default"}
            className="w-full"
            onClick={() => {
              props.blockViewModel.uiAction.updateBlockData(
                props.frameId,
                props.blockViewModel.uiState.activeBlock?.id ?? "",
                changeMap
              );
              changeMap.clear();
            }}
          >
            <RefreshCw className="h-4 w-4 me-2" />
            Update data
          </Button>
        ) : (
          <></>
        )}
        <div className={"flex flex-col gap-2 pt-4"}>
          {visibilityVariable?.id ? (
            <>
              <div
                key={visibilityVariable?.id + Math.random().toString()}
                className="grid w-full items-center gap-1.5 bg-muted p-2 rounded-lg"
              >
                <Label
                  key={visibilityVariable?.id + Math.random().toString()}
                  htmlFor={"blockVisbilityTextFieldPicker_" + visibilityVariable?.id}
                >
                  {visibilityVariable?.id}
                </Label>
                <Input
                  disabled={true}
                  autoComplete="off"
                  key={visibilityVariable?.id + Math.random().toString()}
                  defaultValue={visibilityVariable?.text}
                  type="blockVisbilityTextFieldPicker"
                  id={"blockVisbilityTextFieldPicker_" + visibilityVariable?.id}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {Array.from(data?.keys() ?? []).map((key) => {
            const dataItem = data?.get(key);
            if (!dataItem) return <></>;
            return (
              <BlockTextFieldDataPickerView
                variables={props.variables}
                key={dataItem.id + Math.random().toString()}
                itemKey={dataItem.key}
                itemValue={dataItem.value}
                onChange={(key, newValue) => {
                  changeMap.set(key, newValue);
                }}
              />
            );
          })}
        </div>
      </ScrollArea>
    </>
  );
};

type BlockTextFieldDataPickerViewProps = {
  variables: { id: string; text: string }[];
  itemKey: string;
  itemValue: string;
  onChange: (key: string, newValue: string) => void;
};

export const BlockTextFieldDataPickerView = (props: BlockTextFieldDataPickerViewProps) => {
  return (
    <>
      <div
        key={props.itemKey + Math.random().toString()}
        className="grid w-full items-center gap-1.5 bg-muted p-2 rounded-lg"
      >
        <Label
          key={props.itemKey + Math.random().toString()}
          title={props.itemKey ?? ""}
          htmlFor={"blockDataPicker_" + props.itemKey}
        >
          {props.itemKey}
        </Label>
        <Combobox
          key={props.itemKey + Math.random().toString()}
          list={props.variables}
          defaultValue={props.itemValue}
          onSelect={(key: string) => {
            props.onChange(props.itemKey ?? "", key);
          }}
        />
      </div>
    </>
  );
};
