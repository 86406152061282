import { Alert, AlertDescription } from "@/infrastructure/uikit/components/ui/alert";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/infrastructure/uikit/components/ui/dialog";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import { Trash2 } from "lucide-react";
import React, { FC, useState } from "react";
import { FrameViewModel } from "./FrameViewModel";

export type FrameDeleteViewProps = {
  frameName: string;
  frameRoute: string;
  frameViewModel: FrameViewModel;
  onDeleteFrame: () => void
};

export const FrameDeleteView: FC<FrameDeleteViewProps> = (props: FrameDeleteViewProps) => {
  const [name, setName] = useState("");

  return (
    <Dialog>
      <DialogTrigger asChild={true}>
        <Trash2 className="w-4 h-4 mx-2" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <span>{`Are you sure to delete '${props.frameName}' frame?`}</span>
          </DialogTitle>
          <DialogDescription>
            <div className="flex flex-col space-y-4 pt-4">
              <div className="flex flex-col gap-2">
                <Alert variant="destructive">
                  <AlertDescription>
                    Please note that deleting the frame will also delete any related flows, blocks, and actions. To
                    confirm the deletion please type the name of the frame. This action cannot be undone.
                  </AlertDescription>
                </Alert>
              </div>
              <Label className="sr-only" htmlFor="name">
                Name
              </Label>
              <Input
                id="name"
                placeholder="name"
                type="text"
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect="off"
                onChange={(event: any) => setName(event.target.value)}
              />
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogClose>
          <Button
            onClick={() => {
              props.frameViewModel.uiAction.deleteFrame(props.frameRoute);
              props.onDeleteFrame()
            }}
            disabled={props.frameName !== name}
            variant={"destructive"}
            className="w-full"
          >
            Delete
          </Button>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
};
