import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/infrastructure/uikit/components/ui/dialog";
import {DropdownMenuItem} from "@/infrastructure/uikit/components/ui/dropdown-menu";
import {Trash2} from "lucide-react";
import React, {FC} from "react";
import {VariableModel} from "../domain/model/VariableModel";
import {VariableViewModel} from "./VariableViewModel";
import {Button} from "@/infrastructure/uikit/components/ui/button";

export type VariableDeleteViewProps = {
  frameId: string;
  variable: VariableModel;
  variableViewModel: VariableViewModel;
};

export const VariableDeleteView: FC<VariableDeleteViewProps> = (
  props: VariableDeleteViewProps
) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <Trash2 className="w-4 h-4 mx-2"/>
          Delete
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle asChild>
            <span>Delete</span>
          </DialogTitle>
          <DialogDescription>
            <div className="flex flex-col space-y-4 pt-4">
              <div className="flex flex-col gap-2">
                {`Are you sure to delete '${props.variable?.key}' variable?`}
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              onClick={() => {
                props.variableViewModel.uiAction.deleteVariable(
                  props.frameId,
                  props.variable.key ?? ""
                );
              }}
              variant={"destructive"}
              className="w-full"
            >
              Delete
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
