import { gql } from "@apollo/client";

export const GET_VARIABLE_QUERY = gql`
  query variables($frameId: String!) {
    variables(frameId: $frameId) {
      id
      key
      value
      type
    }
  }
`;

export const ADD_VARIABLE_MUTATION = gql`
  mutation addVariable($input: AddVariableInput!) {
    addVariable(input: $input) {
      key
    }
  }
`;

export const UPDATE_VARIABLE_MUTATION = gql`
  mutation updateVariable($input: UpdateVariableInput!) {
    updateVariable(input: $input) {
      key
    }
  }
`;

export const DELETE_VARIABLE_MUTATION = gql`
  mutation deleteVariable($input: DeleteVariableInput!) {
    deleteVariable(input: $input) {
      key
    }
  }
`;
