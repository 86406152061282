import React from "react";
import { DashboardView } from "./DashboardView";
import { ProjectUsageView } from "@/feature/project/presentation/ProjectUsageView";

export const dashboardRoutes = [
  {
    path: "/",
    element: <DashboardView />,
  },
  {
    path: "/project/usage/:id",
    element: <ProjectUsageView />,
  },
];
