import { gql } from "@apollo/client";

export const CREATE_ORGANIZATION_MUTATION = gql`
  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
    }
  }
`;
export const GET_ORGANIZATIONS_QUERY = gql`
  query organizations {
    organizations {
      id
      name
      manageable
      seatCount
      contributors {
        id
        role
        user {
          id
          username
          email
        }
      }
    }
  }
`;
export const DELETE_ORGANIZATION_CONTRIBUTOR = gql`
  mutation deleteOrganizationContributor($input: OrganizationContributorInput!) {
    deleteOrganizationContributor(input: $input) {
      id
    }
  }
`;
export const ADD_ORGANIZATION_CONTRIBUTOR = gql`
  mutation addOrganizationContributor($input: OrganizationContributorInput!) {
    addOrganizationContributor(input: $input) {
      id
    }
  }
`;
export const BILLING_PORTAL_SESSION = gql`
  query billingPortalSession($organizationId: String!) {
    billingPortalSession(organizationId: $organizationId)
  }
`;
