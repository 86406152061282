import { gql } from "@apollo/client";

export const GET_LOCALIZATIONS_QUERY = gql`
  query localizations {
    localizations {
      checksum
      projectId
      localizations {
        id
        key
        languageCode
        languageName
        value
      }
    }
  }
`;
export const GET_AVAILABLE_LANGUAGES_QUERY = gql`
  query availableLanguages {
    availableLanguages {
      id
      code
      name
    }
  }
`;
export const GET_LANGUAGES_QUERY = gql`
  query languages {
    languages {
      id
      code
      name
    }
  }
`;

export const ADD_LANGUAGE_MUTATION = gql`
  mutation addLocalizationLanguage($input: AddLanguageInput!) {
    addLanguage(input: $input) {
      id
    }
  }
`;

export const ADD_LOCALIZATION_MUTATION = gql`
  mutation addLocalization($input: AddLocalizationInput!) {
    addLocalization(input: $input) {
      id
    }
  }
`;

export const UPDATE_LOCALIZATION_MUTATION = gql`
  mutation updateLocalization($input: UpdateLocalizationInput!) {
    updateLocalization(input: $input) {
      id
    }
  }
`;

export const DELETE_LOCALIZATION_MUTATION = gql`
  mutation deleteLocalization($input: DeleteLocalizationInput!) {
    deleteLocalization(input: $input) {
      id
    }
  }
`;

export const PUBLISH_LOCALIZATION_MUTATION = gql`
  mutation publishLocalization {
    publishLocalization {
      checksum
    }
  }
`;
