export class MemoryStorage {
  private static instance: MemoryStorage;
  private storage: Record<string, any>;

  private constructor() {
    this.storage = {};
  }

  public static getInstance(): MemoryStorage {
    if (!MemoryStorage.instance) {
      MemoryStorage.instance = new MemoryStorage();
    }
    return MemoryStorage.instance;
  }

  public setItem(key: string, value: any): void {
    this.storage[key] = value;
  }

  public getItem(key: string): any {
    return this.storage[key];
  }

  public removeItem(key: string): void {
    delete this.storage[key];
  }

  public clear(): void {
    this.storage = {};
  }
}
