import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/infrastructure/uikit/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/infrastructure/uikit/components/ui/popover";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";
import { cn } from "@/infrastructure/uikit/lib/utils";
import { Check, ChevronsUpDown } from "lucide-react";
import React, { useState } from "react";

export function Combobox(props: {
  list: { id: string; text: string }[];
  defaultValue: string;
  onSelect: (key: string, value: string) => void;
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(props.defaultValue);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className="w-full justify-between">
          {value ? props.list.find((item) => item.id === value)?.id : "Select..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <Command>
          <CommandInput placeholder="Search..." />
          <CommandEmpty>No item found.</CommandEmpty>
          <CommandGroup>
            <ScrollArea>
              {props.list.map((item) => (
                <CommandItem
                  key={item.id}
                  value={item.id}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? "" : currentValue);
                    setOpen(false);
                    props.onSelect(item.id, item.text);
                  }}
                >
                  <Check className={cn("mr-2 h-4 w-4", value === item.text ? "opacity-100" : "opacity-0")} />
                  {item.id}
                </CommandItem>
              ))}
            </ScrollArea>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
