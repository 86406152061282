import {QuoteModel} from "@/plugin/quote/domain/QuoteModel";
import {QuoteRepository} from "@/plugin/quote/domain/QuoteRepository";

export class QuoteRepositoryImpl implements QuoteRepository {
  getQuotes(): QuoteModel[] {
    return [
      {
        "quote": "Innovation distinguishes between a leader and a follower.",
        "author": "Steve Jobs"
      },
      {
        "quote": "Design is not just what it looks like and feels like. Design is how it works.",
        "author": "Steve Jobs"
      },
      {
        "quote": "The best way to predict the future is to invent it.",
        "author": "Alan Kay"
      },
      {
        "quote": "The only way to do great work is to love what you do.",
        "author": "Steve Jobs"
      },
      {
        "quote": "Simplicity is the ultimate sophistication.",
        "author": "Leonardo da Vinci"
      },
      {
        "quote": "Have the courage to follow your heart and intuition. They somehow already know what you truly want to become.",
        "author": "Steve Jobs",
      },
      {
        "quote": "Stay hungry, stay foolish.",
        "author": "Steve Jobs"
      },
      {
        "quote": "The people who are crazy enough to think they can change the world are the ones who do.",
        "author": "Steve Jobs",
      },
      {
        "quote": "Quality is more important than quantity. One home run is much better than two doubles.",
        "author": "Steve Jobs",
      },
      {
        "quote": "Make it work, make it right, make it fast.",
        "author": "Kent Beck"
      },
      {
        "quote": "You might not think that programmers are artists, but programming is an extremely creative profession. It's logic-based creativity.",
        "author": "John Romero"
      },
      {
        "quote": "The best way to predict the future is to create it.",
        "author": "Peter Drucker"
      },
      {
        "quote": "Design is thinking made visual.",
        "author": "Saul Bass"
      },
      {
        "quote": "Engineering is the closest thing to magic that exists in the world.",
        "author": "Elon Musk"
      },
      {
        "quote": "Move fast and break things. Unless you are breaking stuff, you are not moving fast enough.",
        "author": "Mark Zuckerberg"
      },
    ];
  }
}