import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import React from "react";

const NativeDialog = (props: { panel: React.ReactElement; isOpen: boolean; onClose: () => void }) => {
  return (
    <>
      <Transition appear show={props.isOpen}>
        <Dialog as="div" className="relative z-10 focus:outline-none" onClose={props.onClose}>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/50">
            <div className="flex h-full items-center justify-center p-4">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 transform-[scale(95%)]"
                enterTo="opacity-100 transform-[scale(100%)]"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 transform-[scale(100%)]"
                leaveTo="opacity-0 transform-[scale(95%)]"
              >
                <DialogPanel className="w-full max-w-md rounded-sm sm:rounded-lg bg-background p-6">
                  {props.panel}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default NativeDialog;
