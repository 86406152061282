import React, { FC, useEffect } from "react";
import { FrameModel } from "../domain/model/FrameModel";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import { FrameViewModel } from "./FrameViewModel";
import { FrameCreateView } from "./FrameCreateView";
import { Home, Square } from "lucide-react";
import { FrameDeleteView } from "./FrameDeleteView";
import { FrameUpdateView } from "./FrameUpdateView";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";

export type FrameViewProps = {
  frameViewModel: FrameViewModel;
  onFrameSelect: () => void;
  onDeleteFrame: () => void;
};

export const FrameView: FC<FrameViewProps> = (props: FrameViewProps) => {
  useEffect(() => {
    props.frameViewModel.uiAction.getScaffold();
  }, []);

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="mb-2">
          <FrameCreateView frameViewModel={props.frameViewModel} />
        </div>
        <ScrollArea>
          <div className="space-y-2">
            {props.frameViewModel.uiState.scaffold?.frames?.map((frame: FrameModel) => {
              return (
                <Button
                  key={frame.id}
                  variant={props.frameViewModel.uiState.selectedFrame?.id === frame.id ? "secondary" : "ghost"}
                  className="w-full justify-between"
                >
                  <div
                    onClick={() => {
                      props.frameViewModel.uiAction.selectFrame(frame);
                      props.onFrameSelect();
                    }}
                    className="flex flex-row w-full"
                  >
                    {frame.isStarter ? <Home className="h-4 w-4 mx-2" /> : <Square className="h-4 w-4 mx-2" />}
                    {frame.name}
                  </div>
                  <div className="flex flex-row">
                    <FrameUpdateView
                      frameId={frame.id}
                      framRoute={frame.route}
                      framName={frame.name}
                      framIsStarter={frame.isStarter}
                      frameViewModel={props.frameViewModel}
                    />
                    <FrameDeleteView
                      frameRoute={frame.route}
                      frameName={frame.name}
                      frameViewModel={props.frameViewModel}
                      onDeleteFrame={() => {
                        props.onDeleteFrame();
                      }}
                    />
                  </div>
                </Button>
              );
            })}
          </div>
        </ScrollArea>
      </div>
    </>
  );
};
