import { quoteRepository } from "@/plugin/quote/di/QuoteComponent";
import { QuoteModel } from "@/plugin/quote/domain/QuoteModel";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export type WorkspaceUiState = {
  progress: number;
  leftPanel: string | null;
  rightPanel: string | null;
  quote: QuoteModel | null;
};

export type WorkspaceUiAction = {
  getQuote(): void;
  selectLeftPanel(action: string): void;
  selectRightPanel(action: string): void;
  openLocalization(): void;
  openIntegration(): void;
  openVariable(frameId: string): void;
  openSetting(projectId: string): void;
};

export type WorkspaceViewModel = {
  uiState: WorkspaceUiState;
  uiAction: WorkspaceUiAction;
};

export const useWorkspaceViewModel = () => {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState({} as WorkspaceUiState);

  function getQuote() {
    const quotes = quoteRepository.getQuotes();
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setUiState({
      ...uiState,
      quote: quotes[randomIndex],
    });
  }

  function selectLeftPanel(action: string) {
    setUiState({
      ...uiState,
      leftPanel: action,
    });
  }

  function selectRightPanel(action: string) {
    setUiState({
      ...uiState,
      rightPanel: action,
    });
  }

  function openLocalization() {
    navigate(`localization`);
  }

  function openIntegration() {
    navigate(`integration`);
  }

  function openVariable(frameId: string) {
    navigate(`variable?frameId=${frameId}`);
  }

  function openSetting(projectId: string) {
    navigate(`setting?projectId=${projectId}`);
  }

  return {
    uiState: uiState,
    uiAction: {
      getQuote,
      selectLeftPanel,
      selectRightPanel,
      openLocalization,
      openIntegration,
      openVariable,
      openSetting,
    },
  } as WorkspaceViewModel;
};
